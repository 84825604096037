import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import MediaFiles from './components/ProductMedia/MediaFiles';
import store from 'store-js';

function ProductMedia() {
  const currentTab = store.get('productMediaTab') || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set('productMediaTab', selected);
  const handleTabChange = useCallback(
      (selectedTabIndex) => setSelected(selectedTabIndex),
      [],
  );

  const tabs = [
    {
      id: "ProductMedia",
      title: "Product Media",
      content: "Product Media",
      page: <MediaFiles />,
      panelID: "ProductMedia",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}

export default ProductMedia;
