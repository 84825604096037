import React, { Component } from "react";
import { Page, Card, Tabs, ThemeProvider } from "@shopify/polaris";
// import axios from "axios";
import axios from "../../../axiosInstance";
import cloneDeep from "lodash/cloneDeep";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Modal, Button, Alert, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { suppressDeprecationWarnings } from "moment";
import { stubFalse } from "lodash";

var XLSX = require("xlsx");

const customComparator = (valueA, valueB) => {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};
class Fnfeventsinvitelist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllCommunityModules,
      value: "InvitedUser",
      rowHeight: 45,
      headerHeight: 45,
      overlayNoRowsTemplate:
        '<div className="ag-overlay-loading-top text-center"><p>No Records Found </p><div className="loaderr"></div></div></div>',
      overlayLoadingTemplate:
        '<div className="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div className="loader5"></div></div></div>',
      columnDefs2: [
        {
          headerName: "Name",
          field: "displayName",
          width: 200,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Email",
          field: "email",
          width: 200,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Invited Date",
          field: "createdAt",
          width: 100,
          cellRenderer: (data) => {
            return moment(data.data.createdAt).format("MM/DD/YYYY h:mm:ss a");
          },
        },
      ],
      defaultColDef: {
        resizable: true,
        domLayout: "autoHeight",
      },
      openmodel: false,
      currentEventID: "",
      rowData: [],
      pageData: [],
      searchValue: "",
      searchType: "",
      rowDataExcel: [],
      exportInProgress: false,
      selectedRowData: [],
      reinviteLoader: false,
      showMessage: false,
      opencreateusermodel: false,
      searchText: "",
      importError: "",
      importData: null,
      isUploading: false,
      disableSubmit: false,
      showSuccess: false,
    };

    this.FileUploadRef = React.createRef();
  }

  componentDidMount() {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    const headers = {
      "Content-Type": "application/json"
    };
    axios
      .post(
        `/api/eventInviteListNew?event=${this.props.currentEventID}&currentTag=${this.props.currentTag}`,
        data,
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then(
        (success) => {
          this.setState({
            rowData: success.rows,
            pageData: success,
          });
          if (success.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onReady = (customer = null) => {
    this.setState({
      showMessage: false,
    });

    this.FileUploadRef.current.value = "";
    let data = {
      searchValue: this.state.searchValue || "",
      searchType: this.state.searchType || "",
    };
    const headers = {
      "Content-Type": "application/json"
    };
    this.closemodel();
    this.showLoadingOverlay();
    axios
      .post(
        `/api/eventInviteListNew?event=${this.props.currentEventID}&currentTag=${this.props.currentTag}`,
        data,
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then(
        (success) => {
          // if(customer && success.rows?.length < 10 ){
          //     const {id,email,created_at:createdAt}=customer
          //     success.rows = [...success.rows,{id,email,createdAt}]
          // }

          this.setState({
            rowData: success.rows,
            pageData: success,
          });

          this.hideLoadingOverlay();
          if (success.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
          this.hideLoadingOverlay();
        }
      );
  };

  onSearch = () => {
    console.log(this.state.searchType);
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    const headers = {
      "Content-Type": "application/json"
    };
    axios
      .post(
        `/api/eventInviteListNew?event=${this.props.currentEventID}&currentTag=${this.props.currentTag}`,
        data,
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
            id: "",
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  onSearchData = (e) => {
    if (e.target.value === "" || e.target.value) {
      this.parseDate(e.target.value);
      this.setState({ searchText: e.target.value });
    }
  };

  // eslint-disable-next-line no-undef
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    const gridWidth = document.getElementById("myGrid").offsetWidth;
    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);
      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.gridApi.setDomLayout("autoHeight");
    document.querySelector("#myGrid").style.height = "";
  };

  parseDate(input) {
    const parsedDate = moment(input, "MM/DD/YYYY");
    if (input.length == 10 && parsedDate.isValid()) {
      this.setState({
        searchType: "Date",
        searchValue: moment(parsedDate).format("DD-MM-YYYY"),
      });
    } else {
      this.setState({
        searchType: "Email",
        searchValue: input,
      });
    }
  }

  onBtNext = () => {
    this.setState({ selectedRowData: [], reinviteLoader: false });
    this.closeSuccessMessage();
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    const headers = {
      "Content-Type": "application/json"
    };
    axios
      .post(
        `/api/eventInviteListNew?event=${this.props.currentEventID}&direction=next&last_id=${this.state.pageData.nextCursor}&currentTag=${this.props.currentTag}`,
        data,
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  onBtPrevious = () => {
    this.setState({ selectedRowData: [], reinviteLoader: false });
    this.closeSuccessMessage();
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    const headers = {
      "Content-Type": "application/json"
    };
    axios
      .post(
        `/api/eventInviteListNew?event=${this.props.currentEventID}&direction=previous&last_id=${this.state.pageData.previousCursor}&currentTag=${this.props.currentTag}`,
        data,
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  //export excel
  exportexcelrow = () => {
    this.closeSuccessMessage();
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    const headers = {
      "Content-Type": "application/json"
    };
    this.setState({ exportInProgress: true });
    axios
      .post(
        `/api/eventInviteList/excelexport?event=${this.props.currentEventID}`,
        data,
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then(
        (sucess) => {
          console.log("export data", sucess);
          this.setState(
            {
              rowDataExcel: sucess.data,
            },
            () => this.exportexcel()
          );
          if (sucess.data.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
          this.setState({ exportInProgress: false });
        }
      );
  };
  exportexcel = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    const { rowDataExcel } = this.state;
    console.log("excel ", rowDataExcel);
    const sheetname =
      "Event_Users_Report_" + moment().format("MM_DD_YYYY_ h:mm:ss_A");
    const worksheet = workbook.addWorksheet("Event_Users_Report_1");
    worksheet.mergeCells("A1", "F1");
    worksheet.getCell(
      "A1,B1,C1,D1,"
    ).value = `${this.props.currentEventName} - Users Report`;
    worksheet.getCell("A1,B1,C1,D1,").alignment = { horizontal: "center" };
    worksheet.getCell("A1,B1,C1,D1,").font = {
      bold: true,
      name: "Roboto",
      size: 12,
    };

    worksheet.getCell("A5").value = "Invited on";
    worksheet.getCell("A5").alignment = { horizontal: "left" };
    worksheet.getCell("A5").font = { bold: true, name: "Roboto", size: 10 };
    worksheet.getCell("B5").value = moment().format("MM/DD/YYYY h:mm:ss A ");
    worksheet.getCell("B5").alignment = {
      vertical: "bottom",
      horizontal: "left",
    };
    // worksheet.addRow({"":''})
    worksheet.getRow(9).values = ["Email", "Tags", "Invited Date"];
    worksheet.columns.forEach((column) => {
      column.width = 25;
    });
    worksheet.getRow(9).font = {
      bold: true,
      name: "Roboto",
      size: 10,
    };
    worksheet.columns = [
      { key: "Email", width: 35 },
      { key: "Tags", width: 35 },
      { key: "Invited Date", width: 35 },
    ];

    var row_count = 9;
    rowDataExcel.forEach((e, index) => {
      row_count++;
      let fliternp = e.tags.filter((tag) =>
        tag.toLowerCase().startsWith("np:")
      );
      let tagnp = "";
      if (fliternp.length > 0) {
        tagnp = fliternp[0].split(":")[1] || "";
      }
      worksheet.addRow({
        Email: e.email != null ? e.email : "",
        Tags: e.tags.join(", "),
        "Invited Date": moment(e.createdAt).format("MM/DD/YYYY"),
      });
    });
    const insideColumns = ["A", "B", "C", "D"]; // 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U','V','V','W' ]
    var last_row_count = 1;
    worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
      last_row_count++;
      if (rowNumber !== 9 && rowNumber > 9) {
        insideColumns.forEach((c) => {
          worksheet.getCell(`${c}${row_count}`).border = {
            top: { style: "none" },
            left: { style: "none" },
            bottom: { style: "thin" },
            right: { style: "none" },
          };
          // if(c!=='B'){
          worksheet.getCell(`${c}${rowNumber}`).alignment = {
            horizontal: "left",
          };
          //  }
          if (c === "V") {
            worksheet.getCell(`V${rowNumber}`).border = {
              top: { style: "none" },
              bottom: { style: "none" },
              left: { style: "none" },
              right: { style: "thin" },
            };
            if (`V${row_count + 1}`) {
              worksheet.getCell(`V${row_count}`).border = {
                top: { style: "none" },
                bottom: { style: "thin" },
                left: { style: "none" },
                right: { style: "thin" },
              };
            }
          }
          if (rowNumber >= 2) {
            if (rowNumber % 2 === 0) {
              worksheet.getCell(`${c}${rowNumber}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "D6EDED" },
              };
            } else {
              worksheet.getCell(`${c}${rowNumber}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF" },
              };
            }
          }
        });
      }
    });
    insideColumns.forEach((v) => {
      if (v !== "Z") {
        worksheet.getCell(`${v}${9}`).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "none" },
          right: { style: "none" },
        };
        if (v === "V") {
          worksheet.getCell(`${v}${9}`).border = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "none" },
            right: { style: "thin" },
          };
        }
        // worksheet.getCell(`${v}${6}`).alignment = { horizontal: "center" }
        worksheet.getCell(`${v}${9}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "43ABAC" },
        };
      }
    });
    let FileSaver = require("file-saver");
    // save workbook to disk
    // eslint-disable-next-line no-undef
    workbook.xlsx
      .writeBuffer("D:/InviteExport.xlsx")
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), sheetname + ".xlsx")
      )
      .catch((err) => console.log("Error writing excel export", err));
    this.setState({ exportInProgress: false });
  };

  sampleFile = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    const sheetname = "sample_file";
    const worksheet = workbook.addWorksheet("sample_file");

    worksheet.getRow(1).values = [
      "first_name",
      "last_name",
      "email",
      "additional_tags",
    ];

    worksheet.columns.forEach((column) => {
      column.width = 25;
    });
    worksheet.getRow(1).font = {
      bold: true,
      name: "Roboto",
      size: 10,
    };

    worksheet.columns = [
      { key: "first_name", width: 35 },
      { key: "last_name", width: 35 },
      { key: "email", width: 35 },
      { key: "additional_tags", width: 35 },
    ];

    worksheet.addRow({
      first_name: "first",
      last_name: "last",
      email: "sample@sample.com",
      additional_tags: "sampletag1,sampletag2",
    });

    let FileSaver = require("file-saver");

    workbook.xlsx
      .writeBuffer("D:/sample.xlsx")
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), sheetname + ".xlsx")
      )
      .catch((err) => console.log("Error writing excel export", err));
  };
  getNewColumnDef = (columnDefs2) => {
    columnDefs2[0] = {
      headerName: "Name",
      field: "displayName",
      width: 200,
      sortingOrder: ["asc", "desc"],
      comparator: customComparator,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    };
    console.log(columnDefs2);
    return columnDefs2;
  };

  bulkReInvite = (e) => {
    e.preventDefault();

    let data = this.state.selectedRowData.map((row) => {
      return {
        id: row.id,
        email: row.email,
      };
    });

    //console.log("post data", data)
    axios
      .post("/api/bulkreinvite", data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            reinviteLoader: true,
          });
          setTimeout(() => {
            this.gridApi.deselectAll();
            this.setState({
              reinviteLoader: false,
              selectedRowData: [],
            });
          }, 5000);
          // console.log("success",sucess);
        },

        (error) => {
          console.log(error);
        }
      );
  };
  opencreateuser = () => {
    this.setState({
      opencreateusermodel: true,
    });
    this.closeSuccessMessage();
  };

  changeHandler = async (event) => {
    this.closeSuccessMessage();
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          this.processExcel(reader.result);
        };
        reader.readAsBinaryString(event.target.files[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  processExcel = (data) => {
    this.setState({ importError: "" });
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    if (excelRows.length > 0) {
      if (excelRows[0]?.email) {
        this.setState({ isUploading: true });
        this.connected(excelRows);
      } else {
        this.setImportError(
          `Excel should have "email" header. Check the sample file.`,
          4000
        );
      }
    } else {
      this.setImportError("No rows found in Excel file", 4000);
    }
  };

  setImportError = (ErrorText, TimeOut = 3000) => {
    this.setState({ importError: ErrorText });
    this.FileUploadRef.current.value = "";
    setTimeout(() => {
      this.setState({ importError: "" });
    }, TimeOut);
  };

  connected = (params) => {
    let payload = {
      excelData: params,
      isExcel: true,
    };


    axios
      .post(`/api/eventinviteuser/${this.props.currentEventID}`, payload)
      .then((response) => response.data)
      .then(
        (result) => {
          this.FileUploadRef.current.value = "";
          this.setState({ showMessage: true, isUploading: false }, () =>
            setTimeout(() => {
              this.onReady();
            }, 3000)
          );
        },
        (err) => {
          console.log(err);
          this.setImportError(
            "Error while uploading file. Please try again later.",
            4000
          );
          this.setState({ isUploading: false });
        }
      );
  };

  oncreateuser = () => {
    let isvalidfailed = false;
    if (!this.state.first_name) {
      this.setState({ notvalidfname: true });
      isvalidfailed = true;
    } else this.setState({ notvalidfname: false });
    if (!this.state.last_name) {
      this.setState({ notvalidlname: true });
      isvalidfailed = true;
    } else this.setState({ notvalidlname: false });

    // if (!this.state.additional_tags) {
    //         this.setState({ notvalidtags: true });
    //         isvalidfailed = true;
    // }
    // else
    // this.setState({ notvalidtags: false });

    console.log("Email", this.state.email);

    let isemailvalid = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g
    ).test(this.state.email);

    if (!isemailvalid) {
      this.setState({ notvalidemail: true });
      isvalidfailed = true;
    } else this.setState({ notvalidemail: false });

    if (isvalidfailed) {
      return;
    }

    const postdata = {
      excelData: [
        {
          email: this.state.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          additional_tags: this.state.additional_tags,
        },
      ],
      isExcel: false,
    };
    this.setState({ disableSubmit: true });

    document.querySelector(".event-msg").innerHTML = "";

    axios
      .post(`/api/eventinviteuser/${this.props.currentEventID}`, postdata)
      .then((response) => response.data)
      .then(
        (result) => {
          setTimeout(() => {
            this.onReady(result?.customer);
            this.setState({ showSuccess: true });
          }, 4000);
        },
        (err) => {
          console.log(err);
          this.setState({ disableSubmit: false });
          document.querySelector(".event-msg").innerHTML =
            err.response.status +
            " Error: " +
            err.response.data?.message +
            "<br>";
        }
      );
  };

  showLoadingOverlay() {
    this.gridApi.showLoadingOverlay();
  }

  hideLoadingOverlay() {
    this.gridApi.hideOverlay();
  }

  closemodel = () => {
    this.setState({
      opencreateusermodel: false,
      id: "",
      loading: false,
      email: "",
      firstName: "",
      lastName: "",
      first_name: "",
      last_name: "",
      additional_tags: "",
      notvalidemail: false,
      notvalidfname: false,
      notvalidlname: false,
      disableSubmit: false,
      additional_tags: "",
      notvalidtags: false,
    });
  };
  firstname = (e) => {
    if (!e.target.value) this.setState({ notvalidfname: true });
    else this.setState({ notvalidfname: false });
    this.setState({ first_name: e.target.value });
  };

  lastname = (e) => {
    if (!e.target.value) this.setState({ notvalidlname: true });
    else this.setState({ notvalidlname: false });

    this.setState({ last_name: e.target.value });
  };

  tags = (e) => {
    // if (!e.target.value)
    //     this.setState({ notvalidtags: true });
    // else
    //     this.setState({ notvalidtags: false });

    this.setState({ additional_tags: e.target.value });
  };
  email = (e) => {
    this.setState({ email: e.target.value });
  };
  bluremail = (e) => {
    let isemailvalid = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g
    ).test(e);
    if (!isemailvalid) {
      this.setState({ notvalidemail: true });
    } else {
      this.setState({ notvalidemail: false });
    }

    console.log("isemailvalid ", isemailvalid);
    // this.setState({ email: e })
  };

  closeSuccessMessage() {
    this.setState({ showSuccess: false });
  }

  render() {
    const {
      selectedRowData,
      reinviteLoader,
      opencreateusermodel,
      email,
      notvalidemail,
      searchText,
      showSuccess,
      first_name,
      last_name,
      firstName,
      lastName,
      notvalidfname,
      notvalidlname,
      notvalidtags,
      additional_tags,
    } = this.state;
    return (
      <div>
        <div className="headertop">
          <label style={{ color: "red" }}>
            * Please allow few mins to reflect the changes.
          </label>
          <button
            className="transprant"
            onClick={() => {
              this.onReady();
              this.closeSuccessMessage();
            }}
          >
            <i className="facss fa fa-refresh fa-2x"></i>
          </button>
        </div>
        <div className="headertop header_search_import">
          <div className="headerbottom searchbar">
            <div className="inputbox">
              <input
                className="mr-1 searchtinput"
                maxlength="60"
                value={searchText}
                name="searchtext"
                onKeyPress={(event) => event.key === "Enter" && this.onSearch()}
                onChange={this.onSearchData}
                autoComplete="autocomplete_off"
              ></input>
              <button className="btn btn-primary mr-3" onClick={this.onSearch}>
                Search
              </button>
            </div>
            <div className="inputhint">
              <small>Please enter email address</small>
            </div>
          </div>
          <div className="importblock">
            <div className="importsamplefile">
              <a href="#" onClick={this.sampleFile}>
                Download Sample File
              </a>
            </div>
            <div>
              <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                id="contained-button-file"
                single
                type="file"
                ref={this.FileUploadRef}
                onChange={this.changeHandler}
              />
            </div>
            {/* <div>
                        <button className="export btn btn-primary importsubmit">
                         Submit Import
                        </button>
                    </div> */}
            {this.state.isUploading && (
              <div>
                <Spinner animation="border" />
                File uploading...{" "}
              </div>
            )}
            {this.state.showMessage && (
              <div className="showImportMessage">
                File is uploaded to server, check the page few min later{" "}
              </div>
            )}
            {this.state.importError && (
              <div className="showImportMessage">{this.state.importError}</div>
            )}
          </div>
        </div>

        {reinviteLoader && (
          <Alert variant="primary">
            <Spinner animation="border" /> Background reinvite process is
            started...
          </Alert>
        )}
        {showSuccess && (
          <Alert variant="primary">
            {" "}
            User successfully created. It may take few seconds to reflect the
            changes. Click refresh icon to see the changes.
          </Alert>
        )}
        <div className="headertop_eventlist">
          <div className="headeruser">
            <div className="headerexcel export_header">
              <div>
                <button
                  onClick={this.opencreateuser}
                  className="export btn btn-primary mr-3"
                >
                  Invite User
                </button>
              </div>
              {this.state.rowData.length > 0 && (
                <>
                  <div>
                    <button
                      onClick={this.exportexcelrow}
                      className="export btn btn-primary mr-3"
                      disabled={this.state.exportInProgress}
                    >
                      Export Excel
                    </button>
                  </div>
                  {this.props.currentTag == "invited" && (
                    <div className="headeruser">
                      <button
                        onClick={(e) => {
                          this.bulkReInvite(e);
                        }}
                        disabled={selectedRowData?.length > 0 ? false : true}
                        className="export btn btn-primary mr-3"
                      >
                        Reinvite User
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>

            {this.state.exportInProgress && (
              <div>
                <Spinner animation="border" /> Don't close the popup. Export is
                in progress...
              </div>
            )}
          </div>
        </div>
        <Modal
          show={opencreateusermodel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.closemodel}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invite User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>
              <div class="event-msg"></div>
            </strong>
            <fieldset>
              <legend>User Details</legend>
              <div className="space">
                <div className="text"> First Name</div>
                <input
                  className={notvalidfname ? "boderred" : ""}
                  value={first_name}
                  onChange={this.firstname}
                />
              </div>
              {notvalidfname && (
                <div className="inputError">
                  <small> First Name not valid.</small>
                </div>
              )}
              <div className="space">
                <div className="text"> Last Name</div>
                <input
                  className={notvalidlname ? "boderred" : ""}
                  value={last_name}
                  onChange={this.lastname}
                />
              </div>
              {notvalidlname && (
                <div className="inputError">
                  <small> Last Name not valid.</small>
                </div>
              )}
              <div className="space checkboxc">
                <div className="text"> Email</div>
                <input
                  className={notvalidemail ? "boderred" : ""}
                  value={email}
                  onChange={this.email}
                  onBlur={() => this.bluremail(this.state.email)}
                />
              </div>
              {notvalidemail && (
                <div className="inputError">
                  <small> Email not valid.</small>
                </div>
              )}
              <div className="space checkboxc">
                <div className="text"> Tags</div>
                <input
                  className={notvalidtags ? "boderred" : ""}
                  value={additional_tags}
                  onChange={this.tags}
                />
              </div>
              {notvalidtags && (
                <div className="inputError">
                  <small> Tags not valid.</small>
                </div>
              )}
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.disableSubmit}
              onClick={this.oncreateuser}
            >
              Submit
            </Button>
            <Button
              disabled={this.state.disableSubmit}
              onClick={this.closemodel}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
            marginTop: "20px",
          }}
        >
          <div
            id="myGrid"
            style={{
              width: "100%",
              "font-size": "14px",
              "row-height": "60px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={
                this.props.currentTag == "invited"
                  ? this.getNewColumnDef(this.state.columnDefs2)
                  : this.state.columnDefs2
              }
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              paginationPageSize={this.state.paginationPageSize}
              domLayout={this.state.domLayout}
              pagination={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={true}
              rowSelection={"multiple"}
              onSelectionChanged={() => {
                this.setState({
                  selectedRowData: this.gridApi.getSelectedRows(),
                });
              }}
            />
          </div>
          <div className="headerbottom">
            <button
              className="previous"
              disabled={!this.state.pageData.hasPreviousPage}
              onClick={() => this.onBtPrevious()}
            >
              Previous
            </button>
            <button
              className="next"
              disabled={!this.state.pageData.hasNextPage}
              onClick={() => this.onBtNext()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Fnfeventsinvitelist;
