import { useState, useEffect, useRef } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  DropZone,
  Frame,
  Icon,
  IndexTable,
  Pagination,
  Select,
  Thumbnail,
  TextField,
  Toast,
  useIndexResourceState,
} from "@shopify/polaris";
import { SearchMinor, NoteMinor } from "@shopify/polaris-icons";

import { useSettings } from "../../../AppContext";

import { CUSTOMERS_SORT_FIELDS, BANNER_STATUS } from "./constants";

const XLSX = require("xlsx");

function Customers() {
  const { customerId } = useSettings();

  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  // Add Customer

  const defaultAmount = "0";
  const defaultReference = "New account";
  const maxRecordsUpdate = 100;

  const [customerForm, setCustomerForm] = useState(false);
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(defaultAmount);
  const [reference, setReference] = useState(defaultReference);

  const toggleCustomerForm = () =>
    setCustomerForm((customerForm) => !customerForm);
  const emailChanged = (value) => setEmail(value);
  const amountChanged = (value) => setAmount(value);
  const referenceChanged = (value) => setReference(value);
  const cancelAddCustomer = () => setCustomerForm(false);
  const showAddCustomer = () => {
    toggleCustomerForm();

    if (importForm) {
      toggleImportForm();
    }
  };

  const [importForm, setImportForm] = useState(false);
  const toggleImportForm = () => setImportForm((importForm) => !importForm);
  const showImportCustomers = () => {
    toggleImportForm();

    if (customerForm) {
      toggleCustomerForm();
    }
  };

  const addCustomer = async () => {
    const data = {
      admin: customerId,
      email,
      amount,
      reference,
    };

    const url = `/api/credits/customer`;

    try {
      const response = await axios.post(url, JSON.stringify(data));

      if (response.status === 200 || response.status === 400) {
        const { status, msg, customer } = response.data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Add Customer",
            msg ? msg : `Error creating customer`
          );
        }
        if (status && customer) {
          setEmail("");
          setAmount(defaultAmount);

          setQuery({
            ...query,
            page: 1,
            sortField: "createdDate",
            sortOrder: "desc",
          });
        }
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.msg;

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Add Customer",
        msg ? msg : `Error creating customer: ${err}`
      );
    }
  };

  // Issue Credit

  const [creditForm, setCreditForm] = useState(false);
  const toggleCreditForm = () => setCreditForm((creditForm) => !creditForm);
  const cancelCredit = () => setCreditForm(false);
  const showCredit = () => toggleCreditForm();

  const issueCredit = async () => {
    const data = {
      admin: customerId,
      ids: selectedResources.join(","),
      amount,
      reference,
    };

    const url = `/api/credits/issueCredits`;

    try {
      const response = await axios.post(url, JSON.stringify(data));

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Credit Issue",
            msg ? msg : `Error in credit processing`
          );
        }

        if (status) {
          getCustomers();

          setAmount("");
          setReference("");
        }
      }
    } catch (err) {
      console.log(err);

      let msg = "";
      const data = err.response?.data;

      if (data) {
        if (data.errMsg) {
          msg = data.errMsg;
        } else if (data.msg) {
          msg = data.msg;
        }
      }

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Issue Credit",
        msg ? msg : `Error crediting customer(s): ${err}`
      );
    }
  };

  // Adjust Balance

  const [adjustmentForm, setAdjustmentForm] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(false);
  const toggleAdjustmentForm = () =>
    setAdjustmentForm((adjustmentForm) => !adjustmentForm);
  const cancelAdjustment = () => setAdjustmentForm(false);
  const showAdjustment = () => toggleAdjustmentForm();

  const adjustBalance = async () => {
    const data = {
      admin: customerId,
      ids: selectedResources.join(","),
      amount,
      reference,
    };

    const url = `/api/credits/adjustBalances`;

    try {
      const response = await axios.post(url, JSON.stringify(data));

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Adjust Balance",
            msg ? msg : `Error adjusting customer's balance`
          );
        }

        if (status) {
          getCustomers();

          setAmount("");
          setReference("");
        }
      }
    } catch (err) {
      console.log(err);

      let msg = "";
      const data = err.response?.data;

      if (data) {
        if (data.errMsg) {
          msg = data.errMsg;
        } else if (data.msg) {
          msg = data.msg;
        }
      }

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Adjust Balance",
        msg ? msg : `Error adjusting balance: ${err}`
      );
    }
  };

  // Index table

  const [customers, setCustomers] = useState([]);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [total, setTotal] = useState();

  const resourceName = {
    singular: "customer",
    plural: "customers",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(customers);

  const rowMarkup = customers.map(({ id, name, email, balance }, index) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources.includes(id)}
      position={index}
    >
      <IndexTable.Cell>{name}</IndexTable.Cell>
      <IndexTable.Cell>{email}</IndexTable.Cell>
      <IndexTable.Cell>{balance}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  useEffect(() => {
    setCustomerForm(false);

    if (!selectedResources || selectedResources.length === 0) {
      setCreditForm(false);
      setAdjustmentForm(false);

      setAmount(defaultAmount);
      setReference(defaultReference);
    } else {
      setAmount("");
      setReference("");

      setUpdateDisabled(selectedResources.length > maxRecordsUpdate);

      if (selectedResources.length > maxRecordsUpdate) {
        updateBanner(
          BANNER_STATUS.WARNING,
          "Customers update",
          `Max customers to update is set to: ${maxRecordsUpdate}`
        );
      }
    }
  }, [selectedResources]);

  const debounceTimeout = 1000;
  const timerRef = useRef(null);

  const debounce = function (func, delay) {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(func, delay);
  };

  const getDataTableSource = (list) => {
    const lst = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      lst.push({
        id: item.shopifyCustomerId,
        name: item.name,
        email: item.email,
        balance: `$${item.balance.toFixed(2)}`,
      });
    }

    return lst;
  };

  const [query, setQuery] = useState({
    search: "",
    sortField: "name",
    sortOrder: "asc",
    page: 1,
    pageSize: 10,
  });

  const queryParams = () => {
    const params = [];
    const keys = Object.keys(query);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (query[key] === undefined || query[key] === null) {
        continue;
      }

      if (typeof query[key] === "string" || query[key] instanceof String) {
        if (query[key].length === 0) {
          continue;
        }
      }

      params.push(`${key}=${encodeURIComponent(query[key])}`);
    }

    return params;
  };

  const getCustomers = async () => {
    const params = queryParams();
    const url = `/api/credits/customers?${params.join("&")}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, hasPrevious, hasNext, total } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Customers",
            msg ? msg : `Error loading customers`
          );
        }

        setHasPrevious(hasPrevious);
        setHasNext(hasNext);

        const totalPages = Math.ceil(total / query.pageSize);
        setTotal(totalPages);

        let list = data.list;

        if (!list || list.length === 0) {
          setCustomers([]);
          return;
        }

        const lst = getDataTableSource(list);
        setCustomers(lst);
      } else {
        setCustomers([]);

        updateBanner(
          BANNER_STATUS.CRITICAL,
          "Customers",
          msg ? msg : `Error querying customers`
        );
      }
    } catch (err) {
      console.log(err);

      setCustomers([]);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Customers update",
        `Error getting customers`
      );
    }
  };

  const searchChanged = (value) => {
    setQuery({
      ...query,
      search: value,
    });
  };

  useEffect(() => {
    debounce(getCustomers, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const toggleToast = () => setShowToast((showToast) => !showToast);

  const search = () => getCustomers();

  const exportCustomers = async () => {
    const data = {
      admin: customerId,
      search: query.search,
    };

    const url = `/api/credits/customers/export`;

    try {
      const response = await axios.post(url, JSON.stringify(data));

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Export Customers",
            msg ? msg : `Error exporting customer(s)`
          );
        }
      }
    } catch (err) {
      console.log(err);

      let msg = "";
      const data = err.response?.data;

      if (data) {
        if (data.errMsg) {
          msg = data.errMsg;
        } else if (data.msg) {
          msg = data.msg;
        }
      }

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Customers update",
        msg ? msg : `Error exporting customer(s): ${err}`
      );
    }
  };

  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState(null);

  useEffect(() => {
    const list = CUSTOMERS_SORT_FIELDS.map((item) => {
      return {
        label: item.title,
        value: item.id,
      };
    });

    setSortOptions(list);

    const found = CUSTOMERS_SORT_FIELDS.find(
      (item) => item.field === query.sortField && item.order === query.sortOrder
    );

    if (found) {
      setSelectedSort(found.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortChanged = (option) => {
    setSelectedSort(option);

    const found = CUSTOMERS_SORT_FIELDS.find(
      (item) => item.id === parseInt(option)
    );

    setQuery({
      ...query,
      sortField: found.field,
      sortOrder: found.order,
    });
  };

  // Import Customers

  const [file, setFile] = useState();

  const handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) =>
    setFile(acceptedFiles[0]);

  const validFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const fileUpload = !file && (
    <DropZone.FileUpload actionHint="Accepts Excel file" />
  );

  const uploadedFile = file && (
    <div>
      <Thumbnail
        size="small"
        alt={file.name}
        source={
          validFileTypes.includes(file.type)
            ? window.URL.createObjectURL(file)
            : NoteMinor
        }
      />
      <div>
        {file.name} <span>{file.size} bytes</span>
      </div>
    </div>
  );

  useEffect(() => {
    if (file) {
      console.log("type", file.type);
      openFile(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const openFile = async (file) => {
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();

      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          processExcel(reader.result);
          toggleImportForm();
          setFile(null);
        };
        reader.readAsBinaryString(file);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];

    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );

    const json = mapImportData(excelRows);
    console.log({ json });

    const { status: validateStatus, msg: validateMsg } = validateImportData(
      json
    );

    if (!validateStatus) {
      updateBanner(BANNER_STATUS.CRITICAL, "Data validation", validateMsg);
      return;
    }

    loadImportData(json);
  };

  const mapImportData = (data) => {
    return data.map((item) => {
      return {
        email: item.Email,
        credit: item.Credit,
        adjustment: item.Adjustment,
        reference: item.Reference,
      };
    });
  };

  const validateImportData = (data) => {
    if (!validateDuplicates(data)) {
      return {
        status: false,
        msg: `Duplicate email(s) found in import file`,
      };
    }

    if (!validateEmpty(data)) {
      return {
        status: false,
        msg: `Some record(s) do not have Credit or Ajustment amount or References.`,
      };
    }

    if (!validateAmountsRecord(data)) {
      return {
        status: false,
        msg: `Some record(s) have both Credit and Ajustment amounts. Only one of them is allowed.`,
      };
    }

    if (!validateAmountsDatatype(data)) {
      return {
        status: false,
        msg: `Some record(s) have not valid amount.`,
      };
    }

    return {
      status: true,
    };
  };

  const validateDuplicates = (data) => {
    const unique = [...new Set(data.map((item) => item.email))];
    return unique.length === data.length;
  };

  const validateEmpty = (data) => {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      if (
        (item.credit === undefined || !item.credit) &&
        (item.adjustment === undefined || !item.adjustment)
      ) {
        return false;
      } else if (item.reference === undefined || !item.reference) {
        return false;
      }
    }

    return true;
  };

  const validateAmountsRecord = (data) => {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      if (item.credit !== undefined && item.adjustment !== undefined) {
        return false;
      }
    }

    return true;
  };

  const validateAmountsDatatype = (data) => {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      console.log({ item });

      if (item.credit !== undefined && isNaN(item.credit)) {
        return false;
      } else if (item.adjustment !== undefined && isNaN(item.adjustment)) {
        return false;
      }
    }

    return true;
  };

  const loadImportData = async (list) => {
    console.log(`loadImportData:`, list);

    const data = {
      admin: customerId,
      list,
    };
    console.log({ data });

    const url = `/api/credits/customers/import`;

    try {
      const response = await axios.post(url, JSON.stringify(data));

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        if (status) {
          updateBanner(BANNER_STATUS.INFO, "Import Customers", msg);
          return true;
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Import Customers",
            msg ? msg : `Error importing customer(s)`
          );
        }
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.msg;

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Import Customers",
        msg ? msg : `Error importing customers: ${err}`
      );
    }

    return false;
  };

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <div
        style={{
          display: "flex",
          padding: "10px",
        }}
      >
        <div style={{ minWidth: "315px" }}>
          <TextField
            placeholder="Search Customers"
            value={query.search}
            onChange={searchChanged}
            autoComplete="off"
            prefix={<Icon source={SearchMinor} color="base" />}
            alignItems="end"
          />
        </div>
        <div style={{ marginLeft: 5 }}>
          <Button onClick={search}>Search</Button>
        </div>

        <div style={{ marginLeft: 5, minWidth: "130px" }}>
          <Button
            primary
            fullWidth
            disabled={!customers || customers.length === 0}
            onClick={exportCustomers}
          >
            Export Filtered
          </Button>
        </div>

        <div style={{ width: "100%" }}></div>

        <div style={{ marginLeft: 30 }}>
          <Select
            label=""
            placeholder="Sort By"
            options={sortOptions}
            onChange={sortChanged}
            value={selectedSort}
          />
        </div>
        {total > 0 && (
          <label
            style={{
              minWidth: "90px",
              textAlign: "right",
              paddingLeft: "10px",
              paddingTop: "8px",
            }}
          >
            {query.page} of {total}
          </label>
        )}
        <div style={{ marginLeft: 10 }}>
          <Pagination
            hasPrevious={hasPrevious}
            onPrevious={() => {
              setQuery({
                ...query,
                page: query.page - 1,
              });
            }}
            hasNext={hasNext}
            onNext={() => {
              setQuery({
                ...query,
                page: query.page + 1,
              });
            }}
          />
        </div>
      </div>

      {selectedResources &&
        selectedResources.length > 0 &&
        !creditForm &&
        !adjustmentForm && (
          <div
            style={{
              paddingLeft: "10px",
              paddingBottom: "10px",
              borderBottom: "rgba(225, 227, 229, 1) solid",
            }}
          >
            <ButtonGroup>
              {!creditForm && !adjustmentForm && (
                <Button disabled={updateDisabled} onClick={showCredit}>
                  Issue Credit
                </Button>
              )}
              {!creditForm && !adjustmentForm && (
                <Button disabled={updateDisabled} onClick={showAdjustment}>
                  Adjust Balance
                </Button>
              )}
            </ButtonGroup>
          </div>
        )}

      {creditForm && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "400px",
            }}
          >
            <Card>
              <div
                style={{
                  padding: "10px",
                }}
              >
                <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Add Credit to {selectedResources.length} Customer
                  {selectedResources.length === 1 ? "" : "s"}
                </label>
                <div style={{ marginTop: "10px", maxWidth: "190px" }}>
                  <TextField
                    value={amount}
                    onChange={amountChanged}
                    label="Credit"
                    type="number"
                    autoComplete="off"
                    helpText={<span>Add amount to selected Customer(s).</span>}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <TextField
                    value={reference}
                    onChange={referenceChanged}
                    label="Reference"
                    type="text"
                    autoComplete="off"
                    helpText={
                      <span>Provide reference for this transaction.</span>
                    }
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonGroup>
                    <Button onClick={cancelCredit}>Cancel</Button>
                    <Button primary onClick={issueCredit}>
                      Submit
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}

      {adjustmentForm && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "400px",
            }}
          >
            <Card>
              <div
                style={{
                  padding: "10px",
                }}
              >
                <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Adjust {selectedResources.length} Customer
                  {selectedResources.length === 1 ? "" : "s"} balance
                </label>
                <div style={{ marginTop: "10px", maxWidth: "190px" }}>
                  <TextField
                    value={amount}
                    onChange={amountChanged}
                    label="Adjustment"
                    type="number"
                    autoComplete="off"
                    helpText={
                      <span>Adjust balance for selected Customer(s).</span>
                    }
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <TextField
                    value={reference}
                    onChange={referenceChanged}
                    label="Reference"
                    type="text"
                    autoComplete="off"
                    helpText={
                      <span>Provide reference for this transaction.</span>
                    }
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonGroup>
                    <Button onClick={cancelAdjustment}>Cancel</Button>
                    <Button primary onClick={adjustBalance}>
                      Submit
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}

      {(!selectedResources || selectedResources.length === 0) && !customerForm && (
        <div
          style={{
            paddingLeft: "10px",
            paddingBottom: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
          }}
        >
          <ButtonGroup>
            <Button onClick={showAddCustomer}>Add Customer</Button>
            <Button primary onClick={showImportCustomers}>
              Import
            </Button>
          </ButtonGroup>
        </div>
      )}

      {customerForm && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "400px",
            }}
          >
            <Card>
              <div
                style={{
                  padding: "10px",
                  borderBottom: "rgba(225, 227, 229, 1) solid",
                }}
              >
                <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Add New Customer
                </label>
                <div style={{ marginTop: "10px" }}>
                  <TextField
                    value={email}
                    onChange={emailChanged}
                    label="Email"
                    type="email"
                    autoComplete="email"
                    helpText={
                      <span>
                        We’ll use this email address to find a customer in
                        Shopify and add it to the list.
                      </span>
                    }
                  />
                </div>
                <div style={{ marginTop: "10px", maxWidth: "190px" }}>
                  <TextField
                    value={amount}
                    onChange={amountChanged}
                    label="Credit"
                    type="number"
                    autoComplete="off"
                    helpText={<span>Add amount to new Customer.</span>}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <TextField
                    value={reference}
                    onChange={referenceChanged}
                    label="Reference"
                    type="text"
                    autoComplete="off"
                    helpText={
                      <span>Provide reference for this transaction.</span>
                    }
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonGroup>
                    <Button onClick={cancelAddCustomer}>Cancel</Button>
                    <Button primary onClick={addCustomer}>
                      Submit
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}

      {importForm && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "400px",
            }}
          >
            <DropZone allowMultiple={false} onDrop={handleDropZoneDrop}>
              {uploadedFile}
              {fileUpload}
            </DropZone>
          </div>
        </div>
      )}

      {customers && (
        <IndexTable
          resourceName={resourceName}
          itemCount={customers.length}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          headings={[
            { title: "Name" },
            { title: "Email" },
            { title: "Balance" },
          ]}
        >
          {rowMarkup}
        </IndexTable>
      )}

      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default Customers;
