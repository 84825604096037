import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import cloneDeep from "lodash/cloneDeep";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Stack, RadioButton } from "@shopify/polaris";
import { Modal, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-date-picker";
import moment from "moment";
var XLSX = require("xlsx");

class PriceLogList extends Component {
  constructor(props) {
    super(props);
    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.state = {
      modules: AllCommunityModules,
      value: "",
      valueName: "Disabled",
      result: { ids: [], removeTag: {}, addTag: {} },
      rowHeight: 50,
      headerHeight: 60,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loaderr"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs2: [
        {
          headerName: "Date",
          field: "DisplayDate",
          width: 70,
          cellRenderer: (params) => {
            return params.value ? moment(params.value).format("MM/DD/YYYY h:mm:ss a") : null;
          },
        },
        {
          headerName: "SKU",
          field: "Sku",
          width: 70,
          comparator: customComparator,
        },
        {
          headerName: "Prior Day Price",
          field: "PreviousPrice",
          width: 70,
          comparator: customComparator,
          cellRenderer: (params) => {
            if (params.value) {
              const val = params.value.toFixed(2);
              return val;
            }
          },
        },
        {
          headerName: "Current Day Price",
          field: "CurrentPrice",
          width: 70,
          comparator: customComparator,
          cellRenderer: (params) => {
            if (params.value) {
              const val = params.value.toFixed(2);
              return val;
            }
          },
        },
        {
          headerName: "Percentage Change",
          field: "PercentageChange",
          width: 70,
          comparator: customComparator,
        },
        {
          headerName: "Compare At Price",
          field: "CompareAtPrice",
          width: 70,
        },
      ],
      columnDefs3: [
        {
          headerName: "Date",
          field: "DisplayDate",
          width: 70,
          cellRenderer: (params) => {
            return params.value ? moment(params.value).format("MM/DD/YYYY h:mm:ss a") : null;
          },
        },
        {
          headerName: "SKU",
          field: "Sku",
          width: 70,
          comparator: customComparator,
        },
        {
          headerName: "Customer Type",
          field: "CustomerType",
          width: 70,
          comparator: customComparator,
        },
        {
          headerName: "Prior Day Price",
          field: "PreviousPrice",
          width: 70,
          comparator: customComparator,
          cellRenderer: (params) => {
            if (params.value) {
              const val = params.value.toFixed(2);
              return val;
            }
          },
        },
        {
          headerName: "Current Day Price",
          field: "CurrentPrice",
          width: 70,
          comparator: customComparator,
          cellRenderer: (params) => {
            if (params.value) {
              const val = params.value.toFixed(2);
              return val;
            }
          },
        },
        {
          headerName: "Percentage Change",
          field: "PercentageChange",
          width: 70,
          comparator: customComparator,
        },
        {
          headerName: "Compare At Price",
          field: "CompareAtPrice",
          width: 70,
        },
        {
          headerName: "Source",
          field: "Source",
          width: 70,
          comparator: customComparator,
        },
      ],
      defaultColDef: {
        flex:1,
        resizable: true,
        domLayout: "autoHeight",
      },

      fullname: "",
      rowData: [],
      pageData: [],
      paginationPageSize: 25,
      loading: false,
      showMessage: false,
      currentPage: 1,
      totalPages: 1,
      per_page: 10,
      rowDataExcel: [],
      startDate: new Date(),
      endDate: new Date(),
      filterEnabled: false,
      openModel: false,
      exportData: "all",
      exportLoader: false,
      is_employeestore: false
    };
  }

  getAllPriceLog = () => {
    const data = {
      per_page: this.state.per_page,
      page: 1,
    };
    this.getPriceLog(data);
  };

  getConfig = async () => {
    //TODO get the store name
    //if(response && response.data){
      //const data = response.data;
      //this.setState({
        //is_employeestore: (data.ISEMPLOYEESTORE && ((data.SQL_SCHEMA == 'EmployeeStore_US') || (data.SQL_SCHEMA == 'EmployeeStore_US_Staging') || (data.SQL_SCHEMA == 'EmployeeStore_CA') || (data.SQL_SCHEMA == 'EmployeeStore_CA_Staging')))
      //})
    //}
  }

  componentDidMount() {
    this.getConfig();
    this.getAllPriceLog();
  }

  getPriceLog = (data) => {
    axios
      .get("/api/pricelog/list", { params: data })
      .then((response) => response.data)
      .then(
        (success) => {
          if (success.data?.results?.length) {
            const perPage = parseFloat(success.data.per_page || 10);
            this.setState({
              rowData: success.data.results,
              paginationPageSize: perPage,
              currentPage: success.data.page,
              totalPages: success.data.total_pages,
            });
          } else {
            this.gridApi.showNoRowsOverlay();
            this.gridApi.setRowData([]);
            this.setState({
              rowData: [],
              currentPage: 0,
              totalPages: 0,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  // eslint-disable-next-line no-undef
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();

    this.gridApi.sizeColumnsToFit();

    const gridWidth = document.getElementById("myGrid").offsetWidth;

    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);

      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();
    }

    this.gridApi.setDomLayout("autoHeight");
    document.querySelector("#myGrid").style.height = "";
  };

  onBtNext = () => {
    if (parseInt(this.state.currentPage) < parseInt(this.state.totalPages)) {
      const data = {
        per_page: this.state.per_page,
        page: parseInt(this.state.currentPage) + 1,
      };
      if (this.state.filterEnabled) {
        data.date_from = moment(this.state.startDate).format(
          "YYYY-MM-DDT"
        )+"00:00:00Z";
        data.date_to = moment(this.state.endDate).format(
          "YYYY-MM-DDT"
        )+"23:59:59Z";
      }
      this.getPriceLog(data);
    }
  };

  onBtPrevious = () => {
    if (parseInt(this.state.currentPage) !== 1) {
      const data = {
        per_page: this.state.per_page,
        page: parseInt(this.state.currentPage) - 1,
      };
      if (this.state.filterEnabled) {
        data.date_from = moment(this.state.startDate).format(
          "YYYY-MM-DDT"
        )+"00:00:00Z";
        data.date_to = moment(this.state.endDate).format(
          "YYYY-MM-DDT"
        )+"23:59:59Z";
      }
      this.getPriceLog(data);
    }
  };

  onSearchFilter = () => {
    this.setState({ filterEnabled: true });

    const data = {
      per_page: this.state.per_page,
      date_from: moment(this.state.startDate).format("YYYY-MM-DDT")+"00:00:00Z",
      date_to: moment(this.state.endDate).format(
        "YYYY-MM-DDT"
      )+"23:59:59Z"
    };
    this.getPriceLog(data);
  };

  clearFilter = () => {
    this.setState(
      {
        filterEnabled: false,
        startDate: new Date(),
        endDate: new Date(),
      },
      () => this.getAllPriceLog()
    );
  };

  openPopUp = () => {
    this.setState({
      openModel: true,
    });
  };

  closeModel = () => {
    this.setState({
      openModel: false,
      exportData: "all",
    });
  };

  limit = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ stp_discount_limit: e.target.value });
    }
  };

  //export excel
  exportExcelRow = () => {
    this.setState({
      exportLoader: true,
    });
    const data = {};
    if (this.state.exportData === "filtered") {
      data.date_from = moment(this.state.startDate).format(
        "YYYY-MM-DDT"
      )+"00:00:00Z";;
      data.date_to = moment(this.state.endDate).format(
        "YYYY-MM-DDT"
      )+"23:59:59Z";
     }
    axios
      .get("/api/pricelog/list/export", { params: data })
      .then((response) => response.data)
      .then(
        (success) => {
          this.setState(
            {
              rowDataExcel: success.data.results,
            },
            () => this.exportexcel()
          );
          if (success.data.results.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
          this.setState({
            openModel: false,
            exportLoader: false,
          });
        }
      );
  };

  exportexcel = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();

    const { rowDataExcel } = this.state;
    const sheetname = "pricelogreport";
    const worksheet = workbook.addWorksheet("pricelogreport_");
    if(this.state.is_employeestore){
      worksheet.columns = [
        { header: "Date", key: "DisplayDate", width: 35 },
        { header: "SKU", key: "Sku", width: 35 },
        { header: "Prior day Price", key: "PreviousPrice", width: 35 },
        { header: "Current day Price", key: "CurrentPrice", width: 35 },
        { header: "Percentage Change", key: "PercentageChange", width: 35 },
        { header: "Compare At Price", key: "CompareAtPrice", width: 35 },
        { header: "Customer Type", key: "CustomerType", width: 35 },
        { header: "Source", key: "Source", width: 35 },
      ];
    }else{

   
    worksheet.columns = [
      { header: "Date", key: "DisplayDate", width: 35 },
      { header: "SKU", key: "Sku", width: 35 },
      { header: "Prior day Price", key: "PreviousPrice", width: 35 },
      { header: "Current day Price", key: "CurrentPrice", width: 35 },
      { header: "Percentage Change", key: "PercentageChange", width: 35 },
      { header: "Compare At Price", key: "CompareAtPrice", width: 35 },
    ];
  }
    rowDataExcel.forEach((e, index) => {
      worksheet.addRow({ ...e });
    });

    let FileSaver = require("file-saver");
    // save workbook to disk
    // eslint-disable-next-line no-undef
    workbook.xlsx
      .writeBuffer("D:/pricelog.xlsx")
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), sheetname + ".xlsx")
      )
      .catch((err) => console.log("Error writing excel export", err));
    this.setState({
      openModel: false,
      exportLoader: false,
    });
  };

  handleExport = () => {
    if (this.state.filterEnabled) this.openPopUp();
    else this.exportExcelRow();
  };
  //export excel

  handleStartDateChange = (e) => {
    if (e) {
      this.setState({
        startDate: e,
      });
    }
  };

  handleEndDateChange = (e) => {
    if (e) {
      this.setState({
        endDate: e,
      });
    }
  };

  isPreviousValid = () => {
    if (parseInt(this.state.currentPage) <= 1) {
      return true;
    }
    return false;
  };

  isNextValid = () => {
    if (parseInt(this.state.currentPage) < parseInt(this.state.totalPages)) {
      return false;
    }
    return true;
  };

  handleRadioChange = (val) => {
    if (val) {
      this.setState({
        exportData: val,
      });
    }
  };

  compareDates = () => {
    const { startDate, endDate } = this.state;
    if (
      moment(startDate).format("YYYY-MM-DD") >
      moment(endDate).format("YYYY-MM-DD")
    ) {
      return true;
    } else return false;
  };

  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex" }}>
            <DatePicker
              onChange={this.handleStartDateChange}
              clearIcon={null}
              value={this.state.startDate}
              name="startDate"
              className="mr-5"
              format="MM/dd/yyyy"
              maxDate={new Date()}
            />
            <DatePicker
              onChange={this.handleEndDateChange}
              clearIcon={null}
              value={this.state.endDate}
              name="endDate"
              className="mr-5"
              format="MM/dd/yyyy"
              maxDate={new Date()}
            />
            <button
              onClick={this.onSearchFilter}
              className="export btn btn-primary mr-5"
              disabled={this.compareDates()}
            >
              Search Filter
            </button>
            <button
              onClick={this.clearFilter}
              className="export btn btn-primary"
              disabled={!this.state.filterEnabled}
            >
              Clear Filter
            </button>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{ display: this.state.exportLoader ? "block" : "none" }}
            >
              Please wait. Export is in progress...{" "}
              <Spinner className="mx-3" animation="border" />
            </div>
            <button
              onClick={this.handleExport}
              className="export btn btn-primary"
            >
              Export Excel
            </button>
          </div>
        </div>
        {/* )} */}

        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "90vh",
              width: "100%",
              "font-size": "14px",
              "row-height": "60px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.is_employeestore ? this.state.columnDefs3 : this.state.columnDefs2}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              // rowSelection={this.state.rowSelection}
              onGridReady={this.onGridReady}
              //onGridSizeChanged={this.onGridSizeChanged.bind(this)}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              paginationPageSize={this.state.paginationPageSize}
              domLayout={this.state.domLayout}
              pagination={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={true}
            />
          </div>
        </div>
        <div className="headerbottom">
          <button
            class="previous"
            disabled={this.isPreviousValid()}
            onClick={() => this.onBtPrevious()}
          >
            Previous
          </button>
          <span>
            Page {this.state.currentPage} of {this.state.totalPages}
          </span>
          <button
            class="next"
            disabled={this.isNextValid()}
            onClick={() => this.onBtNext()}
          >
            Next
          </button>
        </div>

        <Modal
          show={this.state.openModel}
          size="sm"
          centered
          onHide={this.closeModel}
          className="pricelog-model-header"
        >
          <Modal.Header closeButton>
            <Modal.Title>Export Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Stack vertical>
              <RadioButton
                label="All Data"
                checked={this.state.exportData === "all"}
                value="all"
                name="accounts"
                onChange={(e) => this.handleRadioChange("all")}
              />
              <RadioButton
                label="Filtered Data"
                value="filtered"
                name="accounts"
                checked={this.state.exportData === "filtered"}
                onChange={(e) => this.handleRadioChange("filtered")}
              />
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary mr-2"
              onClick={this.exportExcelRow}
            >
              Submit
            </button>
            <button className="btn btn-primary" onClick={this.closeModel}>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default PriceLogList;
