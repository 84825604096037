import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "bootstrap/dist/css/bootstrap.min.css";

import CheckboxRenderer from "../Common/CheckboxRenderer.jsx";
import FilterSOLR from "./FilterSOLR.jsx";
import FilterUISettings from "./FilterUI.jsx";

import { deepEqual } from "../../../utils";

import { COLUMNS } from "./constants";

class FiltersDef extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 40,
      headerHeight: 40,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loader"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs: [
        {
          headerName: "Active",
          field: "active",
          cellRenderer: "checkboxRenderer",
          width: 70,
          sortable: true,
        },
        {
          headerName: "Order",
          field: "order",
          width: 70,
          sortable: true,
        },
        {
          headerName: "Filter",
          field: "name",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Overwrite",
          field: "overwrite",
          sortable: true,
        },
        {
          headerName: "UI Settings",
          field: "icon",
          width: 120,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            const data = params.data;
            const disable = !data.active ? "disabledlink" : "";

            link.className = `updateSettingsBtn btn btn-primary ${disable}`;
            link.disabled = !data.active;
            link.innerHTML = " UI Settings ";

            link.addEventListener("click", (e) => {
              e.preventDefault();
              this.showUISettings(data);
            });

            return link;
          },
        },
        {
          headerName: "SOLR Settings",
          field: "icon",
          width: 120,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            const data = params.data;
            const disable = !data.active ? "disabledlink" : "";

            link.className = `updateSettingsBtn btn btn-primary ${disable}`;
            link.disabled = !data.active;
            link.innerHTML = " SOLR Settings ";

            link.addEventListener("click", (e) => {
              e.preventDefault();
              this.showSolrSettings(data);
            });

            return link;
          },
        },
      ],
      defaultColDef: {
        domLayout: "autoHeight",
      },
      frameworkComponents: {
        checkboxRenderer: CheckboxRenderer,
      },
      rowData: [],
      loading: false,
      statusError: false,
      statusText: "",
      showUISettings: false,
      showSolrSettings: false,
      filter: {},
      updatedFilter: {},
      updateCollections: false,
    };
  }

  componentDidMount() {
    this.getFilters();
  }

  getFilters = async () => {
    const url = `/api/filters/filtersDef`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(url, { headers: headers });

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, filtersDef } = data;

        if (!filtersDef || filtersDef.length === 0) {
          this.gridApi.setRowData([]);

          this.setState({
            rowData: [],
            statusError: !status,
            statusText: msg,
          });

          return;
        }

        this.gridApi.setRowData(filtersDef);

        this.setState({
          rowData: filtersDef,
          statusError: false,
          statusText: msg,
        });
      } else {
        this.gridApi.showNoRowsOverlay();
        this.gridApi.setRowData([]);

        this.setState({
          rowData: [],
          statusError: false,
          statusText: `No filters found.`,
        });
      }
    } catch (err) {
      console.log(err);

      this.gridApi.showNoRowsOverlay();
      this.gridApi.setRowData([]);

      this.setState({
        rowData: [],
        statusError: true,
        statusText: `Error getting filters: ${err}`,
      });
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  onCellValueChanged = async (params) => {
    const colId = params.column.colId;
    let active = params.data.active;
    const name = params.data.name;

    if (colId === COLUMNS.ACTIVE) {
      active = params.value;
    }

    const filter = await this.enableFilter(name, active);

    if (filter) {
      let data = JSON.parse(JSON.stringify(this.state.rowData));
      const index = data.findIndex((item) => item.name === filter.name);
      data[index] = filter;

      data = data.sort((a, b) => {
        if (a.active < b.active) return 1;
        if (a.active > b.active) return -1;

        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;

        return 1;
      });

      this.gridApi.setRowData(data);

      this.setState({
        rowData: data,
        updateCollections: true,
      });
    } else {
      const item = params.node.data;

      if (colId === COLUMNS.ACTIVE) {
        item.active = params.oldValue;
      }

      const changes = [item];
      params.api.applyTransaction({ update: changes });
    }
  };

  enableFilter = async (name, active) => {
    const url = `/api/filters/filtersDef`;

    const data = JSON.stringify({
      name,
      active,
    });

    try {
      const response = await axios.patch(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, filter } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return filter;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error adding ${name}: ${err}`,
      });
    }

    return null;
  };

  // UI Settings

  showUISettings = async (item) => {
    this.setState({
      filter: item,
      updatedFilter: JSON.parse(JSON.stringify(item)),
      showUISettings: true,
    });
  };

  closeUISettings = async () => {
    this.setState({
      showUISettings: false,
    });
  };

  saveUISettings = async () => {
    this.setState({
      showUISettings: false,
    });

    const filter = await this.saveFilterUISettings(this.state.updatedFilter);

    if (filter) {
      let data = JSON.parse(JSON.stringify(this.state.rowData));
      const index = data.findIndex((item) => item.name === filter.name);
      data[index] = filter;

      data = data.sort((a, b) => {
        if (a.active < b.active) return 1;
        if (a.active > b.active) return -1;

        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;

        return 1;
      });

      this.gridApi.setRowData(data);

      this.setState({
        rowData: data,
      });
    }
  };

  saveFilterUISettings = async (filter) => {
    const url = `/api/filters/filtersDef/ui`;

    const data = JSON.stringify(filter);

    try {
      const response = await axios.patch(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, filter } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return filter;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error updating filter's UI settings: ${err}`,
      });
    }

    return null;
  };

  updateSettingsOverwrite = async (e) => {
    const filter = this.state.updatedFilter;
    filter.overwrite = e.target.value;

    this.setState({
      updatedFilter: filter,
    });
  };

  showSolrSettings = async (item) => {
    this.setState({
      filter: item,
      updatedFilter: JSON.parse(JSON.stringify(item)),
      showSolrSettings: true,
    });
  };

  closeSolrSettings = async () => {
    this.setState({
      showSolrSettings: false,
    });
  };

  saveSolrSettings = async () => {
    this.setState({
      showSolrSettings: false,
    });

    const filter = await this.saveFilterSolrSettings(this.state.updatedFilter);

    if (filter) {
      let data = JSON.parse(JSON.stringify(this.state.rowData));
      const index = data.findIndex((item) => item.name === filter.name);
      data[index] = filter;

      data = data.sort((a, b) => {
        if (a.active < b.active) return 1;
        if (a.active > b.active) return -1;

        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;

        return 1;
      });

      this.gridApi.setRowData(data);

      this.setState({
        rowData: data,
      });
    }
  };

  saveFilterSolrSettings = async (filter) => {
    const url = `/api/filters/filtersDef/solr`;

    const data = JSON.stringify(filter);

    try {
      const response = await axios.patch(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, filter } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return filter;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error updating filter's SOLR settings: ${err}`,
      });
    }

    return null;
  };

  filterSettingsChanged = async (data) => {
    this.setState({
      updatedFilter: data,
    });
  };

  updateCollections = async () => {
    const url = `/api/filters/updateCollections`;

    try {
      const response = await axios.get(url);

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        this.setState({
          updateCollections: false,
          statusError: !status,
          statusText: msg,
        });
      }
    } catch (err) {
      console.log(err);

      this.setState({
        updateCollections: false,
        statusError: true,
        statusText: err,
      });
    }
  };

  render() {
    return (
      <div className="pageWrapper">
        <Modal show={this.state.showUISettings} onHide={this.closeUISettings}>
          <Modal.Header closeButton>
            <Modal.Title>Update Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FilterUISettings
              filter={this.state.updatedFilter}
              filterChanged={this.filterSettingsChanged}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeUISettings}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.saveUISettings}
              disabled={deepEqual(this.state.filter, this.state.updatedFilter)}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showSolrSettings}
          onHide={this.closeSolrSettings}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FilterSOLR
              filter={this.state.updatedFilter}
              filterChanged={this.filterSettingsChanged}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeSolrSettings}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.saveSolrSettings}
              disabled={deepEqual(this.state.filter, this.state.updatedFilter)}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            padding: "10px",
          }}
        >
          <div>
            <button
              className="export btn btn-primary mr-4"
              style={{ width: "200px" }}
              disabled={!this.state.updateCollections}
              onClick={this.updateCollections}
            >
              Update Collections
            </button>
          </div>
          <div
            style={{
              color: this.state.statusError ? "red" : "green",
              padding: "5px 10px 0 0",
              marginLeft: "10px",
              fontSize: "15px",
            }}
          >
            {this.state.statusText}
          </div>
        </div>
        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
          }}
        >
          <div
            style={{
              height: "80vh",
              width: "100%",
              "font-size": "14px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              onCellValueChanged={this.onCellValueChanged.bind(this)}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              domLayout={this.state.domLayout}
              suppressPaginationPanel={true}
            />
          </div>
        </div>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default FiltersDef;
