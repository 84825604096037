import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import cloneDeep from "lodash/cloneDeep";
import InviteListTab from "./fnfeventsinvitelisttab";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { suppressDeprecationWarnings } from "moment";

var XLSX = require("xlsx");

const popUpActionArr = ["Create", "Update"];
class FnfeventsList extends Component {
  constructor(props) {
    super(props);

    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };

    this.state = {
      modules: AllCommunityModules,
      value: "InvitedUser",
      ////result: { ids: [], removeTag: {}, addTag: {} },
      rowHeight: 45,
      headerHeight: 45,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loaderr"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs2: [
        {
          headerName: "Event ID",
          field: "EventID",
          width: 30,
          comparator: customComparator,
        },
        {
          headerName: "Event Name",
          field: "Name",
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Event Description",
          field: "Description",
          hide: true,
          width: 70,
        },
        {
          headerName: "Date From",
          field: "DateFrom",
          width: 70,
          cellRenderer: (data) => {
            return moment(data.data.DateFrom).format("MM/DD/YYYY h:mm:ss a");
          },
        },
        {
          headerName: "Date To",
          field: "DateTo",
          width: 70,
          cellRenderer: (data) => {
            return moment(data.data.DateTo).format("MM/DD/YYYY h:mm:ss a");
          },
        },
        {
          headerName: "Created By",
          field: "CreatedBy",
          width: 70,
          hide: true,
          cellRenderer: (data) => {
            if (data.data.CreatedBy == 0) {
              return "Admin";
            } else {
              return data.data.CreatedBy;
            }
          },
        },
        {
          headerName: "IsDeleted",
          field: "IsDeleted",
          width: 35,
          hide: true,
        },
        {
          headerName: "Is Published",
          field: "Active",
          width: 35,
          cellRenderer: (data) => {
            const span = document.createElement("span");
            span.className = data.data.Active ? "published" : "draft";
            span.innerHTML = data.data.Active ? "Published" : "Draft";
            return span;
          },
        },
        {
          headerName: "View Invite",
          field: "icon",
          width: 35,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "btncss btn btn-primary";
            link.innerHTML = '<i class="fa fa-list"> View Invite</i>';
            link.addEventListener("click", (e) => {
              e.preventDefault();
              this.setState({
                currentEventID: params.data.EventID,
                currentEventName: params.data.Name,
                openviewinvite: true,
              });
            });
            return link;
          },
        },
        {
          headerName: "Edit",
          field: "icon",
          width: 35,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "btncss btn btn-primary";
            link.innerHTML = '<i class="fa fa-pencil"> Edit</i>';
            link.addEventListener("click", (e) => {
              e.preventDefault();

              this.setState({
                eventID: params.data.EventID,
                eventName: params.data.Name,
                eventDesc: params.data.Description,
                //eventName: null,
                //eventDesc: null,
                //eventDateFrom: params.data.DateFrom,
                //eventDateTo: params.data.DateTo,

                eventDateFrom: params.data.DateFrom
                  ? params.data.DateFrom === "undefined"
                    ? new Date()
                    : new Date(params.data.DateFrom)
                  : new Date(),
                eventDateTo: params.data.DateTo
                  ? params.data.DateTo === "undefined"
                    ? new Date()
                    : new Date(params.data.DateTo)
                  : new Date(),
                mineventDateFrom: params.data.DateFrom
                  ? params.data.DateFrom === "undefined"
                    ? new Date()
                    : new Date() > new Date(params.data.DateFrom)
                    ? new Date(params.data.DateFrom)
                    : new Date()
                  : new Date(),
                mineventDateTo: params.data.DateFrom
                  ? params.data.DateFrom === "undefined"
                    ? new Date()
                    : new Date() > new Date(params.data.DateFrom)
                    ? new Date(params.data.DateFrom)
                    : new Date()
                  : new Date(),
                eventCreatedBy: params.data.CreatedBy,
                eventActive: params.data.Active,
                notvalifname: false,
                notvalilname: false,
                popUpAction: 1,
              });

              this.Popup();
            });
            return link;
          },
        },
        {
          headerName: "Remove",
          field: "icon",
          width: 35,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "btncss btn btn-danger";
            link.innerHTML = '<i class="fa fa-remove"> Delete </i>';
            link.addEventListener("click", (e) => {
              e.preventDefault();

              let eventID = params.data.EventID;
              let data = {
                archive: "true",
              };
              const headers = {
                "Content-Type": "application/json"
              };
              if (window.confirm("Are you sure you want delete this event?")) {
                axios
                  .patch("/api/archive/" + eventID, data, {
                    headers: headers,
                  })
                  .then((response) => response.data)
                  .then(
                    (success) => {
                      setTimeout(() => {
                        this.onReady();
                      }, 2000);
                      console.log(success.message);
                      //if (success.rows.length === 0) {
                      //  this.gridApi.showNoRowsOverlay();
                      //}
                    },

                    (error) => {
                      console.log(error);
                    }
                  );
                //this.Popup();
              }
            });
            return link;
          },
        },
        {
          headerName: "Reports",
          field: "icon",
          width: 35,
          // hide: true,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "btncss btn btn-success";
            link.innerHTML = '<i class="fa fa-file-excel-o"> Reports </i>';

            link.addEventListener("click", (e) => {
              this.gridApi.showLoadingOverlay();
              e.preventDefault();
              this.setState(
                {
                  eventID: params.data.EventID,
                  eventName: params.data.Name,
                },
                () => this.eventReport()
              );
            });
            return link;
          },
        },
      ],
      defaultColDef: {
        resizable: true,
        domLayout: "autoHeight",
      },
      openmodel: false,
      opencreateusermodel: false,
      fullname: "",
      rowData: [],
      pageData: [],
      paginationPageSize: 10,
      showMessage: false,
      ////profile_crm_id: '',
      ////profile_as400_id: '',
      ////profile_privacy_policy_consent: false,
      ////profile_email_policy_consent: false,
      id: "",
      name: "",
      ////updateresult: { id: {}, event_name: {}, event_desc: {}, email: {}, additional_tags: {} },
      // search
      searchText: "",
      searchValue: "",
      //  searchDate: null,
      //  searchType:null,
      rowDataExcel: [],
      event_name: "",
      event_desc: "",
      eventName: "",
      eventDateFrom: new Date(),
      eventDateTo: new Date(),
      eventCreatedBy: "",
      eventActive: false,
      email: "",
      additional_tags: "",
      notvalidemail: false,
      notvalidfname: false,
      notvalidlname: false,
      notValidEventName: false,
      notValidEventDesc: false,
      notValidEventDateFrom: false,
      notValidEventDateTo: false,
      notValidEventCreatedBy: false,
      notValidEventActive: false,
      popUpAction: 0,
      disableEventSubmit: false,
      openviewinvite: false,
      currentEventID: "",
      currentEventName: "",
      currentEventTab: "",
      hasPreviousPage: false,
      hasNextPage: false,
      openmodelEvent: false,
    };
  }

  componentDidMount() {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    const headers = {
      "Content-Type": "application/json"
    };
    axios
      .get("/api/events", {
        headers: headers,
      })
      .then((response) => response.data)
      .then(
        (success) => {
          const filterData = success;

          // const result = filterData.filter(function(d){
          //     if(d.IsDeleted == true){
          //         return false;
          //     }
          //     return true;
          // });
          const result = this.checkCurrentEventTab(
            filterData,
            this.props.currentEventTab
          );

          //console.log('result',result);
          this.setState({
            //rowData: sucess.data, pageData: sucess
            rowData: result,
            pageData: success,
          });
          //console.log(this.state.rowData);

          if (!success.rows) {
            this.gridApi.showNoRowsOverlay();
          }
        },

        (error) => {
          console.log(error);
        }
      );
  }

  checkCurrentEventTab = (filterData, currentEventTab) => {
    if (currentEventTab === "Published") {
      return filterData.filter(function (d) {
        if (d.Active == false || d.IsDeleted == true) {
          return false;
        }
        return true;
      });
    } else {
      return filterData.filter(function (d) {
        if (d.IsDeleted == true) {
          return false;
        }
        return true;
      });
    }
  };

  // eslint-disable-next-line no-undef
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    const gridWidth = document.getElementById("myGrid").offsetWidth;
    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);
      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.gridApi.setDomLayout("autoHeight");
    document.querySelector("#myGrid").style.height = "";
  };

  onReady = () => {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    const headers = {
      "Content-Type": "application/json"
    };
    axios
      .get("/api/events", {
        headers: headers,
      })
      .then((response) => response.data)
      .then(
        (success) => {
          const filterData = success;

          // const result = filterData.filter(function(d){
          //     if(d.IsDeleted == true){
          //         return false;
          //     }
          //     return true;
          // });
          const result = this.checkCurrentEventTab(
            filterData,
            this.props.currentEventTab
          );
          //console.log('result',result);
          this.setState({
            //rowData: sucess.data, pageData: sucess
            rowData: result,
            pageData: success,
          });
          //console.log(this.state.rowData);

          //if (sucess.rows.length === 0) {
          //this.gridApi.showNoRowsOverlay();
          //}
        },

        (error) => {
          console.log(error);
        }
      );
  };

  changeHandler = async (event) => {
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          this.processExcel(reader.result);
        };
        reader.readAsBinaryString(event.target.files[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  refreshPage = () => {
    window.location.reload(false);
  };

  Popup = () => {
    this.setState({
      openmodel: true,
    });
  };
  PopupEvent = () => {
    this.setState({
      openmodelEvent: true,
    });
  };
  closemodelEvent = () => {
    this.setState({
      openmodelEvent: false,
      eventID: "",
      eventName: "",
    });
  };
  closemodel = () => {
    this.setState({
      openmodel: false,
      opencreateusermodel: false,
      eventID: "",
      loading: false,
      name: "",
      event_name: "",
      event_desc: "",
      email: "",
      eventName: "",
      eventDateFrom: new Date(),
      eventDateTo: new Date(),
      mineventDateFrom: new Date(),
      mineventDateTo: new Date(),
      eventCreatedBy: "",
      eventActive: false,
      lastName: "",
      additional_tags: "",
      notvalidfname: false,
      notvalidlname: false,
      notvalidemail: false,
      notValidEventName: false,
      notValidEventDesc: false,
      notValidEventDateFrom: false,
      notValidEventDateTo: false,
      notValidEventCreatedBy: false,
      notValidEventActive: false,
      popUpAction: 0,
      disableEventSubmit: false,
    });
  };
  reventname = (e) => {
    if (!e.target.value) this.setState({ notValidEventName: true });
    else this.setState({ notValidEventName: false });
    this.setState({ eventName: e.target.value });
  };

  reventdesc = (e) => {
    if (!e.target.value) this.setState({ notValidEventDesc: true });
    else this.setState({ notValidEventDesc: false });
    this.setState({ eventDesc: e.target.value });
  };

  reventdatefrom = (e) => {
    if (!e) this.setState({ notValidEventDateFrom: true });
    else this.setState({ notValidEventDateFrom: false });
    this.setState({ eventDateFrom: e });
  };

  reventdateto = (e) => {
    if (!e) this.setState({ notValidEventDateTo: true });
    else this.setState({ notValidEventDateTo: false });
    this.setState({ eventDateTo: e });
  };

  reventcreatedby = (e) => {
    if (!e.target.value) this.setState({ notValidEventCreatedBy: true });
    else this.setState({ notValidEventCreatedBy: false });
    this.setState({ eventCreatedBy: e.target.value });
  };

  reventactive = (e) => {
    this.setState({ eventActive: e.target.checked });
  };

  onupdate = () => {
    let isvalidfailed = false;
    if (!this.state.eventName) {
      this.setState({ notValidEventName: true });
      isvalidfailed = true;
    } else this.setState({ notValidEventName: false });

    if (!this.state.eventDesc) {
      this.setState({ notValidEventDesc: true });
      isvalidfailed = true;
    } else this.setState({ notValidEventDesc: false });

    if (!this.state.eventDateFrom) {
      this.setState({ notValidEventDateFrom: true });
      isvalidfailed = true;
    } else this.setState({ notValidEventDateFrom: false });

    if (!this.state.eventDateTo) {
      this.setState({ notValidEventDateTo: true });
      isvalidfailed = true;
    } else if (
      Date.parse(this.state.eventDateTo) < Date.parse(this.state.eventDateFrom)
    ) {
      console.log("date to", this.state.eventDateTo);
      console.log("date from", this.state.eventDateFrom);
      this.setState({ notValidEventDateTo: true });
      isvalidfailed = true;
    } else this.setState({ notValidEventDateTo: false });

    if (isvalidfailed) return;
    const activeStatus = this.state.eventActive === true ? "true" : "false";
    //console.log('activeStatus',activeStatus);
    this.setState(
      {
        updateresult: {
          //eventID: this.state.eventID,
          name: this.state.eventName,
          description: this.state.eventDesc,
          dateFrom: this.state.eventDateFrom,
          dateTo: this.state.eventDateTo,
          createdBy: this.state.eventCreatedBy,
          active: activeStatus,
        },
        loading: true,
        disableEventSubmit: true,
      },
      () => this.onupdateresult()
    );
  };

  onupdateresult = () => {
    let {
      eventID: updateEventID,
      updateresult: data,
      popUpAction,
    } = this.state;

    const config = {
      url: popUpAction == 0 ? "/api/event" : `/api/event/${updateEventID}`,
      method: popUpAction == 0 ? "POST" : `PATCH`,
      headers: {
        "Content-Type": "application/json"
      },
      data: JSON.stringify(data || ""),
    };

    axios(config)
      .then((response) => response.id)
      .then(
        (result) => {
          this.setState({ disableEventSubmit: false });
          setTimeout(() => {
            this.onReady();
            this.closemodel();
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false, disableEventSubmit: false });
          document.querySelector(".event-msg").innerHTML =
            err.response.status + " Error: " + err.response.data + "<br>";
        }
      );
  };

  parseDate(input) {
    const parsedDate = moment(input, "MM/DD/YYYY");
    if (input.length == 10 && parsedDate.isValid()) {
      this.setState({
        searchType: "Date",
        searchValue: moment(parsedDate).format("DD-MM-YYYY"),
      });
    } else {
      this.setState({
        searchType: "Email",
        searchValue: input,
      });
    }
  }
  createEvent = (e) => {
    e && e.preventDefault();

    this.setState({
      eventID: "",
      eventName: "",
      eventDesc: "",
      eventDateFrom: new Date(),
      eventDateTo: new Date(),
      mineventDateFrom: new Date(),
      mineventDateTo: new Date(),
      eventCreatedBy: "0",
      eventActive: true,
      notValidEventName: false,
      notValidEventDesc: false,
      notValidEventDateFrom: false,
      notValidEventDateTo: false,
      notValidEventCreatedBy: false,
      popUpAction: 0,
    });

    this.Popup();
  };

  closeviewmodel = () => {
    this.setState({
      openviewinvite: false,
      currentEventID: "",
      currentEventName: "",
    });
  };

  onBtNext = () => {
    this.gridApi.paginationGoToNextPage();
  };

  onBtPrevious = () => {
    this.gridApi.paginationGoToPreviousPage();
  };

  onPaginationChanged = () => {
    if (this.gridApi) {
      let currentPage = this.gridApi.paginationGetCurrentPage();
      let totalPages = this.gridApi.paginationGetTotalPages();
      this.setState({
        hasPreviousPage: currentPage === 0 ? false : true,
        hasNextPage: totalPages === currentPage + 1 ? false : true,
      });
    }
  };

  //export excel
  eventReport = () => {
    let data = {
      eventID: this.state.eventID,
      eventName: this.state.eventName,
    };
    const headers = {
      "Content-Type": "application/json"
    };
    axios
      .post("/api/eventReport", data, {
        headers: headers,
      })
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.gridApi.hideOverlay();
          this.PopupEvent();
        },
        (error) => {
          console.log(error);
          this.gridApi.hideOverlay();
          this.PopupEvent();
        }
      );
  };
  exportexcel = () => {
    this.gridApi.hideOverlay();
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    // const {rowDataExcel,startDate,endDate}=this.state;
    const { rowDataExcel } = this.state;
    const ExcelSheetName = (
      rowDataExcel[0].Name.replace(/ /g, "_") + "_Report_"
    ).toUpperCase();
    const worksheetName = rowDataExcel[0].Name.toUpperCase();
    const worksheetValue = (rowDataExcel[0].Name + " Report").toUpperCase();
    const sheetname = ExcelSheetName + moment().format("MM_DD_YYYY_ h:mm:ss_A");
    const worksheet = workbook.addWorksheet(worksheetName);
    worksheet.mergeCells("A1", "H1");
    worksheet.getCell("A1,B1,C1,D1,E1,F1,G1,H1").value = worksheetValue;
    worksheet.getCell("A1,B1,C1,D1,E1,F1,G1,H1").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A1,B1,C1,D1,E1,F1,G1,H1").font = {
      bold: true,
      name: "Roboto",
      size: 12,
    };
    worksheet.getCell("A3").value = "Event From Date ";
    worksheet.getCell("A3").alignment = { horizontal: "left" };
    worksheet.getCell("A3").font = { bold: true, name: "Roboto", size: 10 };
    worksheet.getCell("B3").value = moment(rowDataExcel[0].DateFrom).format(
      "MM/DD/YYYY h:mm:ss a"
    );
    worksheet.getCell("B3").alignment = {
      vertical: "bottom",
      horizontal: "left",
    };
    worksheet.getCell("A4").value = "Event To Date ";
    worksheet.getCell("A4").alignment = { horizontal: "left" };
    worksheet.getCell("A4").font = { bold: true, name: "Roboto", size: 10 };
    worksheet.getCell("B4").value = moment(rowDataExcel[0].DateTo).format(
      "MM/DD/YYYY h:mm:ss a"
    );
    worksheet.getCell("B4").alignment = {
      vertical: "bottom",
      horizontal: "left",
    };

    worksheet.getCell("A5").value = "Report Created on";
    worksheet.getCell("A5").alignment = { horizontal: "left" };
    worksheet.getCell("A5").font = { bold: true, name: "Roboto", size: 10 };
    worksheet.getCell("B5").value = moment().format("MM/DD/YYYY h:mm:ss A ");
    worksheet.getCell("B5").alignment = {
      vertical: "bottom",
      horizontal: "left",
    };
    // worksheet.addRow({"":''})
    worksheet.getRow(9).values = [
      "S no",
      "Invitee",
      "Invitee's First Name",
      "Invitee's Last Name",
      "Is Invitee Registered",
      "Inviter",
      "Inviter's First Name",
      "Inviter's Last Name",
    ];
    worksheet.columns.forEach((column) => {
      column.width = 25;
    });
    worksheet.getRow(9).font = {
      bold: true,
      name: "Roboto",
      size: 10,
    };
    worksheet.columns = [
      { key: "S no", width: 35 },
      { key: "Invitee", width: 35 },
      { key: "Invitee's First Name", width: 35 },
      { key: "Invitee's Last Name", width: 35 },
      { key: "Is Invitee Registered", width: 35 },
      { key: "Inviter", width: 35 },
      { key: "Inviter's First Name", width: 35 },
      { key: "Inviter's Last Name", width: 35 },
    ];

    var row_count = 9;
    rowDataExcel.forEach((e, index) => {
      row_count++;

      worksheet.addRow({
        "S no": e.CusEmail != null ? index + 1 : "",
        Invitee: e.CusEmail != null ? e.CusEmail : "",
        "Invitee's First Name": e.CusFirstName != null ? e.CusFirstName : "",
        "Invitee's Last Name": e.CusLastName != null ? e.CusLastName : "",
        "Is Invitee Registered":
          e.RegisterCustomer != null ? (e.RegisterCustomer ? "Yes" : "No") : "",
        Inviter: e.InvEmail != null ? e.InvEmail : "",
        "Inviter's First Name": e.InvFirstName != null ? e.InvFirstName : "",
        "Inviter's Last Name": e.InvLastName != null ? e.InvLastName : "",
      });
    });
    const insideColumns = ["A", "B", "C", "D", "E", "F", "G", "H"]; // 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U','V','V','W' ]
    var last_row_count = 1;
    worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
      last_row_count++;
      if (rowNumber !== 9 && rowNumber > 9) {
        insideColumns.forEach((c) => {
          worksheet.getCell(`${c}${row_count}`).border = {
            top: { style: "none" },
            left: { style: "none" },
            bottom: { style: "thin" },
            right: { style: "none" },
          };
          // if(c!=='B'){
          worksheet.getCell(`${c}${rowNumber}`).alignment = {
            horizontal: "left",
          };
          //  }
          if (c === "V") {
            worksheet.getCell(`V${rowNumber}`).border = {
              top: { style: "none" },
              bottom: { style: "none" },
              left: { style: "none" },
              right: { style: "thin" },
            };
            if (`V${row_count + 1}`) {
              worksheet.getCell(`V${row_count}`).border = {
                top: { style: "none" },
                bottom: { style: "thin" },
                left: { style: "none" },
                right: { style: "thin" },
              };
            }
          }
          if (rowNumber >= 2) {
            if (rowNumber % 2 === 0) {
              worksheet.getCell(`${c}${rowNumber}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "D6EDED" },
              };
            } else {
              worksheet.getCell(`${c}${rowNumber}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF" },
              };
            }
          }
        });
      }
    });
    insideColumns.forEach((v) => {
      if (v !== "Z") {
        worksheet.getCell(`${v}${9}`).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "none" },
          right: { style: "none" },
        };
        if (v === "V") {
          worksheet.getCell(`${v}${9}`).border = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "none" },
            right: { style: "thin" },
          };
        }
        // worksheet.getCell(`${v}${6}`).alignment = { horizontal: "center" }
        worksheet.getCell(`${v}${9}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "43ABAC" },
        };
      }
    });
    let FileSaver = require("file-saver");
    // save workbook to disk
    // eslint-disable-next-line no-undef
    workbook.xlsx
      .writeBuffer("D:/sample.xlsx")
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), sheetname + ".xlsx")
      )
      .catch((err) => console.log("Error writing excel export", err));
  };
  //export excel

  render() {
    const {
      openmodel,
      opencreateusermodel,
      name,
      event_name,
      event_desc,
      email,
      eventID,
      eventName,
      eventDesc,
      eventDateFrom,
      eventDateTo,
      eventCreatedBy,
      eventActive,
      notValidEventName,
      notValidEventDesc,
      notValidEventDateFrom,
      notValidEventDateTo,
      notValidEventCreatedBy,
      notValidEventActive,
      popUpAction,
      openviewinvite,
      currentEventID,
      currentEventName,
      mineventDateFrom,
      mineventDateTo,
      openmodelEvent,
    } = this.state;

    return (
      <div>
        <div className="headertop">
          <div class="headeruser">
            <button
              onClick={this.createEvent}
              className="export btn btn-primary mr-5"
            >
              Create Event
            </button>
          </div>
        </div>

        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
            marginTop: "20px",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "90vh",
              width: "100%",
              "font-size": "14px",
              "row-height": "60px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs2}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              paginationPageSize={this.state.paginationPageSize}
              domLayout={this.state.domLayout}
              pagination={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={true}
              onPaginationChanged={this.onPaginationChanged}
            />
          </div>
          <div className="headerbottom">
            <button
              class="previous"
              disabled={!this.state.hasPreviousPage}
              onClick={() => this.onBtPrevious()}
            >
              Previous
            </button>
            <button
              class="next"
              disabled={!this.state.hasNextPage}
              onClick={() => this.onBtNext()}
            >
              Next
            </button>
          </div>
        </div>

        <Modal
          show={openmodel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.closemodel}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>
              <div class="event-msg"></div>
            </strong>

            <fieldset>
              <legend>
                {popUpActionArr[popUpAction]} Event{" "}
                {popUpAction == 0 ? "" : "Details"}
              </legend>
              <div className="space">
                <div className="text"> Event Name: </div>
                <input
                  className={notValidEventName ? "boderred" : ""}
                  value={eventName}
                  onChange={this.reventname}
                />
              </div>
              {notValidEventName && (
                <div className="inputError">
                  <small> Event Name not valid.</small>
                </div>
              )}
              <div className="space">
                <div className="text"> Event Description: </div>
                <input
                  className={notValidEventDesc ? "boderred" : ""}
                  value={eventDesc}
                  onChange={this.reventdesc}
                />
              </div>
              {notValidEventDesc && (
                <div className="inputError">
                  <small> Event Description not valid.</small>
                </div>
              )}
              <div className="space">
                <div className="text"> Date From: </div>
                <div className="datefield">
                  <DateTimePicker
                    onChange={this.reventdatefrom}
                    minDate={mineventDateFrom}
                    clearIcon={null}
                    format="MM/dd/yyyy h:mm:ss a"
                    value={eventDateFrom}
                  />
                </div>
              </div>
              {notValidEventDateFrom && (
                <div className="inputError">
                  <small> Event Date From not valid.</small>
                </div>
              )}
              <div className="space">
                <div className="text"> Date To: </div>
                <div className="datefield">
                  <DateTimePicker
                    onChange={this.reventdateto}
                    minDate={mineventDateTo}
                    clearIcon={null}
                    format="MM/dd/yyyy h:mm:ss a"
                    value={eventDateTo}
                  />
                </div>
              </div>
              {notValidEventDateTo && (
                <div className="inputError">
                  <small> Event Date To not valid.</small>
                </div>
              )}
              <div className="space">
                <div className="text"> Is Published: </div>
                <input
                  name="isActive"
                  type="checkbox"
                  checked={eventActive}
                  onChange={this.reventactive}
                />
              </div>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.disableEventSubmit}
              onClick={() => {
                this.onupdate("Create");
              }}
            >
              Submit
            </Button>
            <Button
              disabled={this.state.disableEventSubmit}
              onClick={this.closemodel}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={openviewinvite}
          size="lg"
          aria-labelledby="contained-viewinvite"
          centered
          onHide={this.closeviewmodel}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-viewinvite">
              {currentEventName} Invite List
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentEventID && (
              <InviteListTab
                currentEventID={currentEventID}
                currentEventName={currentEventName}
              ></InviteListTab>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={openmodelEvent}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.closemodelEvent}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Reports
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>
              <div class="event-msg"></div>
            </strong>

            <div className="space">
              Invitee and Inviter details of the Event will be sent via mail to
              the users configured to receive mail.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.disableEventSubmit}
              onClick={this.closemodelEvent}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default FnfeventsList;
