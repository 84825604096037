import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";

import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";

import "bootstrap/dist/css/bootstrap.min.css";

import CheckboxRenderer from "../Common/CheckboxRenderer.jsx";
import FilterUI from "./FilterUI.jsx";
import SolrOptions from "./SolrOptions.jsx";
import Collections from "./Collections.jsx";

import { deepEqual } from "../../../utils";

const COLUMNS = {
  INCLUDED: "included",
};

class SolrFiltersConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 40,
      headerHeight: 40,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loader"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs: [
        {
          headerName: "Included",
          field: "included",
          cellRenderer: "checkboxRenderer",
          width: 70,
          sortable: true,
        },
        {
          headerName: "Order",
          field: "order",
          width: 70,
          sortable: true,
        },
        {
          headerName: "Filter",
          field: "name",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Overwrite",
          field: "overwrite",
          sortable: true,
        },
        {
          headerName: "Settings",
          field: "icon",
          width: 80,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            const data = params.data;
            const disabled = !data.included;
            const disable = disabled ? "disabledlink" : "";

            link.className = `updateSettingsBtn btn btn-primary ${disable}`;
            link.disabled = disabled;
            link.innerHTML = " Settings ";

            link.addEventListener("click", (e) => {
              e.preventDefault();
              this.showSettings(data);
            });

            return link;
          },
        },
        {
          headerName: "Options",
          field: "icon",
          width: 80,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            const data = params.data;
            const disabled =
              !data.included || !data.options || data.options.length === 0;

            const disable = disabled ? "disabledlink" : "";
            link.className = `updateSettingsBtn btn btn-primary ${disable}`;
            link.disabled = disabled;
            link.innerHTML = " Options ";

            link.addEventListener("click", (e) => {
              e.preventDefault();
              this.showOptions(data);
            });

            return link;
          },
        },
      ],
      defaultColDef: {
        domLayout: "autoHeight",
      },
      frameworkComponents: {
        checkboxRenderer: CheckboxRenderer,
      },
      rowData: [],
      loading: false,
      statusError: false,
      statusText: "",
      collections: [],
      copyCollections: [],
      updatedCopyCollections: [],
      collection: {
        id: 0,
        title: "Select Collection",
      },
      showSettings: false,
      filter: {},
      updatedFilter: {},
      showOptions: false,
      filterOptions: {},
      updatedFilterOptions: {},
      copyToCollections: false,
      showCopy: false,
      copyCollectionsUpdated: false,
    };
  }

  componentDidMount() {
    this.getCollections();
    this.getFilters();
  }

  getCollections = async () => {
    const url = `/api/filters/collections`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, collections } = data;

        this.setState({
          collections,
          statusError: !status,
          statusText: msg,
        });
      } else {
        this.setState({
          collections: [],
          statusError: false,
          statusText: `No collections found.`,
        });
      }
    } catch (err) {
      console.log(err);

      this.setState({
        collections: [],
        statusError: true,
        statusText: `Error getting collectionds: ${err}`,
      });
    }
  };

  getFilters = async (collectionId) => {
    if (!collectionId) {
      this.setState({
        rowData: [],
        copyToCollections: false,
      });

      return;
    }

    const url = `/api/filters/collection/${collectionId}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, filters } = data;

        if (!filters || filters.length === 0) {
          this.gridApi.setRowData([]);

          this.setState({
            rowData: [],
            statusError: !status,
            statusText: msg,
            copyToCollections: false,
          });

          return;
        }

        const list = filters.map((item) => {
          const itm = JSON.parse(JSON.stringify(item.filter));
          itm.collection = item.collection;
          itm.options = item.options;

          return itm;
        });

        this.gridApi.setRowData(list);

        this.setState({
          rowData: list,
          statusError: false,
          statusText: msg,
          copyToCollections: list.filter((item) => item.included).length > 0,
        });
      } else {
        this.gridApi.showNoRowsOverlay();
        this.gridApi.setRowData([]);

        this.setState({
          rowData: [],
          statusError: false,
          statusText: `No filters found.`,
          copyToCollections: false,
        });
      }
    } catch (err) {
      console.log(err);

      this.gridApi.showNoRowsOverlay();
      this.gridApi.setRowData([]);

      this.setState({
        rowData: [],
        statusError: true,
        statusText: `Error getting filters: ${err}`,
        copyToCollections: false,
      });
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  onCellValueChanged = async (params) => {
    const colId = params.column.colId;
    let included = params.data.included;
    const name = params.data.name;
    let collection = params.data.collection;

    if (!collection || collection.length === 0) {
      collection = this.state.collection.title;
    }

    const collectionId = this.state.collection.id.split("/").pop();

    if (colId === COLUMNS.INCLUDED) {
      included = params.value;
    }

    let configuration;

    if (included) {
      configuration = await this.addFilter(collectionId, collection, name);
    } else {
      configuration = await this.removeFilter(collectionId, collection, name);
    }

    if (configuration) {
      let data = JSON.parse(JSON.stringify(this.state.rowData));
      const index = data.findIndex(
        (item) => item.name === configuration.filter.name
      );
      let item = data[index];

      if (included) {
        if (index !== -1) {
          item = configuration.filter;
          item.collection = collection;
          item.options = configuration.options;
          item.included = true;

          data[index] = item;
        }
      } else {
        if (index !== -1) {
          const keys = Object.keys(item);

          for (let i = 0; i < keys.length; i++) {
            const prop = keys[i];

            if (prop !== "name") {
              delete item[prop];
            }
          }

          item.collection = collection;
          item.options = configuration.options;
          item.included = false;
        }
      }

      data = data.sort((a, b) => {
        if (a.included < b.included) return 1;
        if (a.included > b.included) return -1;

        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;

        return 1;
      });

      this.gridApi.setRowData(data);

      this.setState({
        rowData: data,
        copyToCollections: data.filter((item) => item.included).length > 0,
      });
    } else {
      const item = params.node.data;

      if (colId === COLUMNS.INCLUDED) {
        item.included = params.oldValue;
      }

      const changes = [item];
      params.api.applyTransaction({ update: changes });
    }
  };

  addFilter = async (collectionId, collection, name) => {
    const url = `/api/filters/collection/${collectionId}`;

    const data = JSON.stringify({
      collection,
      name,
    });

    try {
      const response = await axios.post(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, configuration } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return configuration;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error adding ${name}: ${err}`,
      });
    }

    return null;
  };

  removeFilter = async (collectionId, collection, name) => {
    const url = `/api/filters/collection/${collectionId}`;

    const data = JSON.stringify({
      collection,
      name,
    });

    try {
      const response = await axios.delete(url, {
        data,
      });

      if (response.status === 200) {
        const { status, msg, configuration } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return configuration;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error adding ${name}: ${err}`,
      });
    }

    return null;
  };

  collectionChanged = async (item) => {
    const id = item.id.split("/").pop();
    this.getFilters(id);

    const copyCollections = this.state.collections.filter(
      (itm) => itm.title !== item.title
    );

    for (let i = 0; i < copyCollections.length; i++) {
      const col = copyCollections[i];
      col.copy = false;
    }

    this.setState({
      collection: item,
      statusError: false,
      statusText: `Loading filters for: ${item.title}`,
      copyCollections,
    });
  };

  // Settings

  showSettings = async (item) => {
    this.setState({
      filter: item,
      updatedFilter: JSON.parse(JSON.stringify(item)),
      showSettings: true,
    });
  };

  closeSettings = async () => {
    this.setState({
      showSettings: false,
    });
  };

  saveSettings = async () => {
    this.setState({
      showSettings: false,
    });

    const collectionId = this.state.collection.id.split("/").pop();
    const configuration = await this.saveFilterSettings(
      collectionId,
      this.state.updatedFilter
    );

    if (configuration) {
      let data = JSON.parse(JSON.stringify(this.state.rowData));
      const index = data.findIndex(
        (item) => item.name === configuration.filter.name
      );
      let item = data[index];

      const filter = configuration.filter;
      filter.collection = item.collection;
      filter.options = configuration.options;
      filter.included = item.included;

      data[index] = filter;

      data = data.sort((a, b) => {
        if (a.included < b.included) return 1;
        if (a.included > b.included) return -1;

        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;

        return 1;
      });

      this.gridApi.setRowData(data);

      this.setState({
        rowData: data,
        copyToCollections: data.filter((item) => item.included).length > 0,
      });
    }
  };

  saveFilterSettings = async (collectionId, filter) => {
    const url = `/api/filters/collection/${collectionId}/filter`;

    const data = JSON.stringify(filter);

    try {
      const response = await axios.patch(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, configuration } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return configuration;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error updating filter's settings: ${err}`,
      });
    }

    return null;
  };

  // Options

  showOptions = async (item) => {
    const itm = {
      collection: item.collection,
      filter: item.name,
      options: item.options,
    };

    this.setState({
      filterOptions: itm,
      updatedFilterOptions: JSON.parse(JSON.stringify(itm)),
      showOptions: true,
    });
  };

  closeOptions = async () => {
    this.setState({
      showOptions: false,
    });
  };

  saveOptions = async () => {
    this.setState({
      showOptions: false,
    });

    const collectionId = this.state.collection.id.split("/").pop();
    const configuration = await this.saveFilterOptionsSettings(
      collectionId,
      this.state.updatedFilterOptions
    );

    if (configuration) {
      let data = JSON.parse(JSON.stringify(this.state.rowData));
      const index = data.findIndex(
        (item) => item.name === configuration.filter.name
      );
      let item = data[index];

      const filter = configuration.filter;
      filter.collection = item.collection;
      filter.options = configuration.options;
      filter.included = item.included;

      data[index] = filter;

      data = data.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );

      this.gridApi.setRowData(data);

      this.setState({
        rowData: data,
        copyToCollections: data.filter((item) => item.included).length > 0,
      });
    }
  };

  saveFilterOptionsSettings = async (collectionId, options) => {
    const url = `/api/filters/collection/${collectionId}/options`;

    const data = JSON.stringify(options);

    try {
      const response = await axios.patch(url, data);
      console.log({ response });

      if (response.status === 200 || response.status === 400) {
        const { status, msg, configuration } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return configuration;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error updating filter's settings: ${err}`,
      });
    }

    return null;
  };

  optionsChanged = async (data) => {
    for (let i = 0; i < data.options.length; i++) {
      const option = data.options[i];
      try {
        option.order = parseInt(option.order);
      } catch (err) {}
    }

    this.setState({
      updatedFilterOptions: data,
    });
  };

  filterSettingsChanged = async (data) => {
    this.setState({
      updatedFilter: data,
    });
  };

  // Copy to Collections

  openCopy = async () => {
    this.setState({
      showCopy: true,
    });
  };

  closeCopy = async () => {
    this.setState({
      showCopy: false,
    });
  };

  collectionsChanged = async (data) => {
    const updated = data.filter((item) => item.copy).length > 0;

    this.setState({
      updatedCopyCollections: data,
      copyCollectionsUpdated: updated,
    });
  };

  copyToCollections = async () => {
    this.setState({
      showCopy: false,
    });

    const filters = this.state.rowData
      .filter((item) => item.included)
      .map((item) => {
        return {
          name: item.name,
          overwrite: item.overwrite,
          order: item.order,
          show: item.show,
          style: item.style,
          counts: item.counts,
          custom: item.custom,
          params: item.params,
          clear: item.clear,
          expand: item.expand,
          optionFinder: item.optionFinder,
          included: item.included,
          collection: item.collection,
        };
      });

    const collections = this.state.updatedCopyCollections
      .filter((item) => item.copy)
      .map((item) => {
        return {
          id: item.id,
          title: item.title,
        };
      });

    for (let i = 0; i < this.state.updatedCopyCollections.length; i++) {
      const col = this.state.updatedCopyCollections[i];
      col.copy = false;
    }

    await this.copy(filters, collections);
  };

  copy = async (filters, collections) => {
    const url = `/api/filters/copy`;

    const data = JSON.stringify({
      filters,
      collections,
    });

    try {
      const response = await axios.post(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return status;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error coping configuration: ${err}`,
      });
    }

    return false;
  };

  render() {
    return (
      <div className="pageWrapper">
        <Modal show={this.state.showSettings} onHide={this.closeSettings}>
          <Modal.Header closeButton>
            <Modal.Title>Update Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FilterUI
              filter={this.state.updatedFilter}
              filterChanged={this.filterSettingsChanged}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeSettings}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.saveSettings}
              disabled={deepEqual(this.state.filter, this.state.updatedFilter)}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showOptions} onHide={this.closeOptions}>
          <Modal.Header closeButton>
            <Modal.Title>Update Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="p-2">
              <SolrOptions
                options={this.state.updatedFilterOptions}
                optionsChanged={this.optionsChanged}
              />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeOptions}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.saveOptions}
              disabled={deepEqual(
                this.state.filterOptions,
                this.state.updatedFilterOptions
              )}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showCopy} onHide={this.closeCopy}>
          <Modal.Header closeButton>
            <Modal.Title>Copy configuration to Collections</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="p-2">
              <Collections
                collections={this.state.copyCollections}
                collectionsChanged={this.collectionsChanged}
              />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeCopy}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.copyToCollections}
              disabled={!this.state.copyCollectionsUpdated}
            >
              Update Configurations
            </Button>
          </Modal.Footer>
        </Modal>

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            padding: "10px",
          }}
        >
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              size="lg"
              id="collectionsDropdown"
            >
              {this.state.collection.title}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {this.state.collections &&
                this.state.collections.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    as="button"
                    style={{
                      fontSize: "14px",
                    }}
                    onClick={() => this.collectionChanged(item)}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>

          <button
            className="export btn btn-primary ml-2 mr-2"
            style={{ width: "200px" }}
            disabled={!this.state.copyToCollections}
            onClick={this.openCopy}
          >
            Copy Configuration
          </button>

          <div
            style={{
              color: this.state.statusError ? "red" : "green",
              padding: "5px 10px 0 0",
              marginLeft: "10px",
              fontSize: "15px",
            }}
          >
            {this.state.statusText}
          </div>
        </div>
        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
          }}
        >
          <div
            style={{
              height: "75vh",
              width: "100%",
              "font-size": "14px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              onCellValueChanged={this.onCellValueChanged.bind(this)}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              domLayout={this.state.domLayout}
              suppressPaginationPanel={true}
            />
          </div>
        </div>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default SolrFiltersConfig;
